<template>
  <q-form class="q-gutter-md q-mt-md">
    <q-file style="width: 100%" outlined dense v-model="file" :filter="checkFileType" @rejected="onRejected"
      label="Select file">
      <template v-slot:prepend>
        <q-icon name="attach_file" />
      </template>
    </q-file>
    <q-btn @click="onSubmit" style="width: 100%" icon="save">Upload</q-btn>
  </q-form>
</template>

<script>
import { ref } from 'vue'
import useChemicalCardStore from '../store/chemical_cards'
import { useDialogPopupStore } from "../store/dialog_popup";

export default {
  setup() {
    return {
      file: ref(),
      chemicalCardStore: useChemicalCardStore(),
      popupStore: useDialogPopupStore(),
    }
  },
  methods: {
    checkFileType(files) {
      const extensions = ['.cif', '.vasp', '.poscar', '.contcar']
      return files.filter(file => {
        let target = false
        for (let i = 0; i < extensions.length; i++) {
          if (file.name.toLowerCase().endsWith(extensions[i]))  {target = true; break;}
        }
        return target
      })
    },
    onRejected() {
      this.popupStore.showAlertMessage("The current extension is not supported, please use .cif, .vasp, .POSCAR, or .CONTCAR")
    },
    onSubmit() {
      if (!this.file) {
        return
      }
      this.loading = true
      const formData = new FormData()
      formData.append('file', this.file, this.file.name)
      this.chemicalCardStore.add(formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // Set the correct content type
        }
      })
        .then((entity) => {
          this.loading = false
          this.cif_file = null
          this.$router.push({ name: 'chemical_cards_info', params: { id: entity.local_user_id } })
        })
        .catch(() => {
          this.loading = false
          this.cif_file = null
        })
    }
  }
}
</script>

<style></style>