<template>
  <q-card flat>
    <q-card-section>
      <div class="text-h6">Alert</div>
    </q-card-section>

    <q-card-section v-if="data" class="q-pt-none">
      <q-card class="q-my-xs" flat v-for="key in Object.keys(data)" :key="key">
        <q-card-section>
          <div class="text-h6">{{ key }}</div>
        </q-card-section>
        <q-card-section v-if="!Array.isArray(data[key])">
          <div class="text-h4">{{ data[key] }}</div>
        </q-card-section>
        <q-card-section v-if="Array.isArray(data[key])">
          <div v-for="value in data[key]" :key="value" class="text-curve">{{ value }}</div>
        </q-card-section>
      </q-card>
    </q-card-section>
    <q-card-section v-if="message" class="q-pt-none">
      <q-card class="q-my-xs" flat>
        {{ message  }}
      </q-card>
    </q-card-section>

    <q-card-actions align="right">
      <q-btn flat label="OK" color="primary" v-close-popup />
    </q-card-actions>
  </q-card>
</template>

<script>
export default {
  props: {
    message: {
      type: String
    },
    data: {
      type: String
    }
  },
}
</script>

<style></style>