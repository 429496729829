<template>
    <q-form class="q-gutter-md q-mt-md">
        <q-toggle style="width: 100%" v-model="userSettings.need_send_email_notification_task" right-label
                    label="Send email notifications?" ></q-toggle>

        <q-btn unelevated color="primary" @click="saveUserSettings" label="Save" ></q-btn>
    </q-form>
</template>

<script>
import { ref } from 'vue'
import { useUserStore } from "../store/user";

export default {
    setup() {
        return {
            userStore: useUserStore(),
            tab: ref('user_info'),
        }
    },
    data() {
        return {
            userSettings: this.userStore.user ? { ...this.userStore.user.user_settings } : {}
        }
    },
    methods: {
        saveUserSettings() {
            this.userStore.updateSettings({
                need_send_email_notification_task: this.userSettings.need_send_email_notification_task,
            })
        }
    }
}
</script>

<style></style>