<template>
  <q-page class="row justify-center">
    <q-card flat style="width: 600px;">
      <q-card-section>
        <div class="text-h3 text-center">Welcome!</div>
        <div class="text-grey text-center">by MatSolver</div>
      </q-card-section>
      <div>
        <q-card flat bordered class="q-pa-sm">
              <div class="text-h6 text-primary">Reset password</div>
              <q-form class="q-gutter-md q-mt-md" @submit="resetPassword">
                <q-input dense outlined clearable v-model="password" :type="isPwd ? 'password' : 'text'" label="Repeat Password">
                  <template v-slot:append>
                    <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                      @click="isPwd = !isPwd" />
                  </template>
                </q-input>
                <q-input dense outlined clearable v-model="repeatPassword" :type="isRepeatPwd ? 'password' : 'text'" label="Repeat Password">
                  <template v-slot:append>
                    <q-icon :name="isRepeatPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                      @click="isRepeatPwd = !isRepeatPwd" />
                  </template>
                </q-input>
                <div class="column justify-evenly items-start">
                  <q-btn dense unelevated type="submit" color="primary-accent" size="lg" class="full-width"
                    label="Login" />
                </div>
              </q-form>
        </q-card>
      </div>
    </q-card>

  </q-page>
</template>

<script>
import { ref } from 'vue'
import { useDialogPopupStore } from "../store/dialog_popup";
import { apiClient } from '../api';
export default {
  setup() {
    return {
      dialogPopupStore: useDialogPopupStore(),
      password: ref(''),
      repeatPassword: ref(''),
      isPwd: ref(true),
      isRepeatPwd: ref(true),
      accept: ref(false),
      alert: ref(false),
      alertMessage: ref("")
    }
  },
  methods: {
    showAlert(error) {
      this.dialogPopupStore.showAlertFromHTTPError(error)
    },
    resetPassword() {
      if (this.password == this.repeatPassword) {
        apiClient.post('/auth/users/reset_password_confirm/', {
          uid: this.$route.params.uid,
          token: this.$route.params.token,
          new_password: this.password,
          re_new_password: this.repeatPassword 
        }).then(() => this.$router.push({ name: 'materials' })).catch((error) => {
          this.dialogPopupStore.showAlertFromHTTPError(error)
        })
      } else {
        this.dialogPopupStore.showAlertData({password: 'Пароли не совпадают'})
      }
    },
  },
}
</script>