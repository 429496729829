import axios from 'axios';

import {useDialogPopupStore} from '../store/dialog_popup'



// axios.interceptors.response.use(
//   response => Promise.resolve(response),
//   error => {
//     Event.$emit('error', 500, error.response.data.message)
//     if (error.response.status === 401) AuthService.logout()
//     const errorMessage = error.response.data.message
//     error.response.data.message = errorMessage.length > 200
//       ? JSON.parse(errorMessage.split('code :').pop()).error.message.split(':')[0]
//       : errorMessage
//     throw error
//     // Promise.reject(error)
//   }
// )

const baseUrl = `${location.protocol}//${location.hostname}/api/`
axios.defaults.withCredentials = true
// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: baseUrl,
})

apiClient.setAuthHeaders = (token) => {
  apiClient.defaults.headers.common['Authorization'] = `JWT ${token}`;

}
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
  if (error.response.status >= 400) {
    const dialogPopupStore = useDialogPopupStore()
    dialogPopupStore.showAlertFromHTTPError(error)
  }
  if (error.response.status == 401) {
    if (localStorage.getItem("tokens", null)) {
      localStorage.removeItem("tokens")
      window.location.reload()
    }
  }
});




export {apiClient, baseUrl}
