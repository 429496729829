<template>
    <div class="row q-my-xs">
        <q-select class="col-12" outlined clearable use-input input-debounce="0" option-value="id"
            v-model="tags" emit-value map-options option-label="text" dense ref="tagsSelector"
            :options="tagsStore.entitiesList" @filter="filterFn" @update:model-value="$emit('updateValue', $event)">
            <template v-slot:selected-item="scope">
                <q-chip outline removable @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex"
                     class="q-ma-none">
                    {{ scope.opt.text }}
                </q-chip>
            </template>
            <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps">
                    <q-chip outline  class="q-ma-none">
                        {{ scope.opt.text }}
                    </q-chip>
                </q-item>
            </template>
        </q-select>
    </div>
</template>

<script>
import { useTagsStore } from "@/store/tags";
import { ref } from "vue";

export default {
    emits: ['updateValue'],
    setup(props) {
        let tagsStore = useTagsStore()
        return {
            tags: ref(props.modelValue),
            tagsStore: tagsStore,
            filteredTagsByInput: ref(tagsStore.entitiesList)
        }
    },
    methods: {
        clearInputTagsSelector() {
            this.$refs.tagsSelector.updateInputValue("", false)
        },
        filterFn(val, update) {
            if (val === '') {
                update(() => {
                    this.filteredTagsByInput = this.tagsStore.entitiesList
                })
                return
            }
            update(() => {
                const needle = val.toLowerCase()
                this.filteredTagsByInput = this.tagsStore.entitiesList.filter(v => v.text.toLowerCase().indexOf(needle) > -1)
            })
        }
    },
    created() {
        this.tagsStore.init();
        // this.filteredTagsByInput = this.tagsStore.entitiesList
    },
}
</script>

<style></style>