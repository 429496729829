<template>
    <div class="row">
        <div class="col-6">Total Core Time: </div>
        <div class="col-6">{{ userStatistics.used_core_time  || 0.0 }} sec</div>
        <div class="col-6">Used Disk Space: </div>
        <div class="col-6">{{ userStatistics.used_disk_space || 0.0 }} MB</div>
        <div class="col-6">Success Calculations: </div>
        <div class="col-6">{{ userStatistics.total_success_calculates || 0  }}</div>
        <div class="col-6">Total Chemical Cards: </div>
        <div class="col-6">{{ userStatistics.total_chemical_card || 0 }}</div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useUserStore } from "../store/user";

export default {
    setup() {
        return {
            userStore: useUserStore(),
            tab: ref('user_info'),
        }
    },
    data() {
        return {
            userStatistics: this.userStore.user ? { ...this.userStore.user.user_statistics } : {}
        }
    },
}
</script>

<style></style>