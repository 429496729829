<template>
    <i v-if="tag && simple" class="q-pr-xs">[{{ tag.text }}]</i>
    <q-chip v-if="tag && !simple" outline clickable icon="sell" :removable="removable" class="q-my-xs cursor-pointer" :key=tag.text @click="click"
        @remove="remove()">
        {{ tag.text }}
    </q-chip>
</template>

<script>
import { useTagsStore } from "@/store/tags";

export default {
    emits: [
        'remove',
        'click'
    ],
    props: {
        tagID: {
            required: true,
            type: String
        },
        removable: {
            required: false,
            default: true
        },
        simple: {
            required: false,
            default: false
        },
    },
    setup(props) {
        return {
            tag: useTagsStore().getById(props.tagID)
        }
    },
    methods: {
        remove() {
            this.$emit('remove', this.tag)
        },
        click() {
            this.$emit('click', this.tag)
        }
    },
}
</script>

<style></style>