import './styles/quasar.sass'

import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import lang from 'quasar/lang/en-US.js'


// To be used on app.use(Quasar, { ... })
export default {
  config: {
    framework: {
      cssAddon: true,
     
  },
}, plugins: [],
  extras: [
    'material-icons',
    'material-icons-round',
    'material-icons-outlined',
  ],
  lang: lang
}