import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from './layouts/Default.vue'
// import HomeTable from './pages/HomeTable.vue'
import About from './pages/About.vue'
import UserChemicalCompunds from './pages/UserChemicalCompunds.vue'
import UserCalculations from './pages/UserCalculations.vue'
import UserBilling from './pages/UserBilling.vue'
import AuthForm from './pages/AuthForm.vue'
import AccountPage from './pages/Account.vue'
import ChemicalCard from './pages/ChemicalCard.vue'
import AddChemicalCard from './pages/AddChemicalCard.vue'
import ResetPassword from './pages/ResetPassword.vue'
import ResetPasswordRequest from './pages/ResetPasswordRequest.vue'

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/account',
        name: 'account',
        component: AccountPage
      },
      {
        path: '/about',
        name: 'about',
        component: About
      },
      {
        path: '/user_billing',
        name: 'user_billing',
        component: UserBilling
      },
      {
        path: '/materials',
        name: 'materials',
        component: UserChemicalCompunds,
        default: true
      },
      {
        path: '/chemical_cards/detail/:id?',
        name: 'chemical_cards_info',
        component: ChemicalCard,
        props: true
      },
      {
        path: '/chemical_cards/add',
        name: 'chemical_cards_add',
        component: AddChemicalCard,
      },
      {
        path: '/calculations',
        name: 'calculations',
        component: UserCalculations,
      },
      {
        default: true,
        path: '/auth',
        name: 'auth',
        component: AuthForm
      },
      {
        path: '/password/reset/confirm/:uid/:token',
        name: 'reset_password',
        component: ResetPassword
      },
      {
        path: '/reset_password_request',
        name: 'reset_password_request',
        component: ResetPasswordRequest
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
