<template>
  <q-page class="column q-pa-xs">
    <div class="">
      <router-link :to="{ name: 'chemical_cards_add', }">
        <q-btn class="q-my-sm" unelevated="" color="positive">Add new material</q-btn>
      </router-link>
      <chemical-compounds-view></chemical-compounds-view>
    </div>
  </q-page>
</template>

<style>
</style>

<script>
import ChemicalCompoundsView from '../components/ChemicalCompoundsView.vue'
export default {
  components: { ChemicalCompoundsView },
  created() {
  }
}
</script>
