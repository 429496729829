import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'
import "./assets/css/style.css";

const pinia = createPinia()
createApp(App).use(Quasar, quasarUserOptions).use(pinia).use(router).mount('#app')
