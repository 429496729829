<template>
    <q-card>
        <q-card-section>
            <div class="text-h6">Add Payment</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
            Only promocode available now! We will add a "Add payment" in Future!
        </q-card-section>

        <q-card-actions align="right">
            <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
    </q-card>
</template>

<script>
export default {

}
</script>

<style></style>