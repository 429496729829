<template>
  <q-form class="q-gutter-md q-mt-md">
    <q-input style="width: 100%" outlined dense  label="mp-xxx"  v-model="matproject_id"></q-input>
    <q-btn @click="onSubmit" style="width: 100%" icon="save">Retrieve from MP</q-btn>
  </q-form>
</template>

<script>
import { ref } from 'vue'
import useChemicalCardStore from '../store/chemical_cards'
import { useDialogPopupStore } from "../store/dialog_popup";
export default {
  setup() {
    return {
      matproject_id: ref(),
      chemicalCardStore: useChemicalCardStore(),
      popupStore: useDialogPopupStore(),
    }
  },
  methods: {
    onSubmit() {
      this.loading = true // add loading state to submit button
      // formData.cif_file = this.cif_file
      this.chemicalCardStore.add({matproject_id: this.matproject_id})
        .then((entity) => {
          this.loading = false
          this.$router.push({ name: 'chemical_cards_info', params: { id:  entity.local_user_id} })
        })
        .catch((error) => {
          this.loading = false
          this.popupStore.showAlertFromHTTPError(error)
        })
    }
  }
}
</script>

<style></style>