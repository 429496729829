<template>
  <q-page class="row justify-center q-pa-xs">
    <div class="column">
    <calculations-view></calculations-view>
    </div>
    
  </q-page>
</template>

<style>
</style>

<script>
import CalculationsView from '../components/CalculationsView.vue'
export default {
  components: { CalculationsView },
  created() {
  }
}
</script>
