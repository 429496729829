<template>
    <q-btn no-caps style="width% 100%" unelevated color="primary" icon="download_for_offline" label="Download structure file">
        <q-menu touch-position>
            <q-list style="min-width: 100px">
                <q-item @click="loadFile('poscar')" clickable v-close-popup>
                    <q-item-section>.POSCAR</q-item-section>
                </q-item>
            </q-list>
            <q-list style="min-width: 100px">
                <q-item @click="loadFile('xyz')" clickable v-close-popup>
                    <q-item-section>.XYZ</q-item-section>
                </q-item>
            </q-list>
            <q-list style="min-width: 100px">
                <q-item @click="loadFile('cif')" clickable v-close-popup>
                    <q-item-section>.CIF</q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>
</template>

<script>
export default {
    props: {
        source_files: {
            reuire:true,
            type: Object
        }
    },
    methods: {
        loadFile(file_type) {
            window.open(this.source_files[file_type], '_blank',)
        }
    }
}
</script>

<style></style>