<template>
    <q-card flat bordered>
        <q-card-section>
            <div class="row items-start no-wrap">
                <div class="col">
                    <div class="text-h6">Balance: {{ userStore.user.user_billing.balance }} {{ userStore.user.user_billing.balance_currency }}</div>
                    <!-- <div class="text-subtitle2">by John Doe</div> -->
                </div>

                <div class="column col-auto">
                    <q-btn @click="showAddPaymentModal = true" class="q-my-xs" color="positive" unelevated="" icon="add">
                        Add money
                    </q-btn>
                    <q-dialog v-model="showAddPaymentModal">
                        <add-payment-modal></add-payment-modal>
                    </q-dialog>
                    <q-btn @click="showPromoCodeModal = true" unelevated color="warning">Enter Promo Code</q-btn>
                    <q-dialog v-model="showPromoCodeModal">
                        <promo-code-modal></promo-code-modal>
                    </q-dialog>
                </div>
            </div>
        </q-card-section>

        <q-separator />

        <q-card-actions>
            <q-expansion-item expand-separator icon="history" label="Show last 30" style="width: 100%">
                <q-scroll-area style="height: 60vh;">
                    <user-biling-history-card class="q-ma-xs" v-for="h in history" :key="h.id"
                        :billing-history="h"></user-biling-history-card>
                </q-scroll-area>
            </q-expansion-item>
        </q-card-actions>
    </q-card>
</template>

<script>
import { useUserStore } from "../store/user";
import UserBilingHistoryCard from './UserBilingHistoryCard.vue';
import PromoCodeModal from './PromoCodeModal.vue';
import { ref } from 'vue'
import AddPaymentModal from './AddPaymentModal.vue';
export default {
    components: { UserBilingHistoryCard, PromoCodeModal, AddPaymentModal },
    setup() {
        const userStore = useUserStore()
        const stepHistory = 30
        return {
            stepHistory,
            userStore,
            currentIndex: ref(stepHistory),
            history: (userStore.user.user_billing.history || []).slice(0, 30).reverse(),
            showPromoCodeModal: ref(false),
            showAddPaymentModal: ref(false),
        }
    },
    methods: {
        onLoad(index, done) {
            setTimeout(() => {
                let user_history = this.userStore.user.user_billing.history
                if (user_history.length > this.currentIndex) {
                    let step = this.currentIndex + this.stepHistory - user_history.length > 0 ? this.stepHistory : user_history.length - this.currentIndex
                    this.history = [...this.userStore.user.user_billing.history.slice(this.currentIndex, this.currentIndex + step), ...this.history]
                    this.currentIndex += step
                }
                done()
            }, 3000)
        }
    }
}
</script>

<style></style>