<template>
    <q-card flat class="q-pa-sm">
        <div v-if="!!!calculationsTypesStore.isInit" class="q-pa-md flex flex-center">
            <q-circular-progress indeterminate rounded size="50px" color="primary" class="q-ma-md" />
        </div>
        <div class="row" v-else>
            <div class="col-12 q-my-sm">
                <q-select :disable="!!chemicalCard.calculation_structure_type" option-value="id" option-label="desc"
                    emit-value map-options dense outlined v-model="calculationStructureType"
                    :options="calculationsStructureTypeOptions" label="Structure type" />
            </div>
            <div>

            </div>
            <div class="col-12 q-my-sm">
                <q-select :disable="!!chemicalCard.calculation_precision" option-value="id" option-label="desc" emit-value
                    map-options dense outlined v-model="calculationPrecision" :options="calculationsPrecisionOptions"
                    label="Calculation accuracy" />

            </div>
            <div v-if="calculationStructureType == 'crystalline' && calculationFormData" class="col-12 column q-my-sm">
                <div class="row">
                    <div class="col-12" v-for="calculationType in calculationsTypes.filter(calcType => calcType.visible)"
                        :key="calculationType.id">
                        <div class="col-12 row" v-if="calculationFormData[calculationType.type]">
                            <div class="col-5">
                                <q-checkbox
                                    @update:model-value="$event => calculationFormDataHandler(calculationType, $event)"
                                    :disable="!!calculationFormData[calculationType.type]?.calculation"
                                    v-model="calculationFormData[calculationType.type].use" :label="calculationType.name" />
                            </div>
                            <div class="col-7 self-end">
                                <calculation-small v-if="calculationFormData[calculationType.type]?.calculation"
                                    :calculation="calculationFormData[calculationType.type]?.calculation"></calculation-small>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
        <div class="col-12 row justify-between">
            <q-btn :disable="disableForm" unelevated="" outline no-caps label="Clear all" @click="setAllTasks(false)"
                color="warning" />
            <q-btn :disable="disableForm" unelevated="" outline no-caps label="Enable all" @click="setAllTasks(true)"
                color="primary" />
            <q-btn :disable="disableForm" unelevated="" label="Run calculations" no-caps @click="submitForm"
                color="primary" />
        </div>
    </q-card>
</template>

<script>
import { ref } from 'vue'
import useCalculationsStore from '../store/calculations'
import useCalculationsTypesStore from '@/store/calculation_types'
import CalculationSmall from './CalculationSmall.vue'
import useChemicalCardsStore from '../store/chemical_cards'
import { CALCULATION_STATES } from '@/shared/calculations'
export default {
    components: { CalculationSmall },
    emits: ['close'],
    props: {
        chemCardId: {
            require: true,
            type: Number
        }
    },
    setup(props) {
        let chemicalCardsStore = useChemicalCardsStore()
        return {
            calculationStates: CALCULATION_STATES,
            chemicalCard: ref(chemicalCardsStore.getById(props.chemCardId)),
            showCalculation: ref(true),
            extraKwargs: ref({
                opt_type: 'full',
                stability_type: 'both',
                single_point: false
            }),
            calculationsStore: useCalculationsStore(),
            calculationsTypesStore: useCalculationsTypesStore(),
            chemicalCardsStore: chemicalCardsStore,
            calculationFormData: ref({}),
            calculationStructureType: ref('crystalline'),
            calculationsStructureTypeOptions: [{
                default: true,
                id: 'crystalline',
                desc: 'Crystalline'
            },
            {
                id: 'molecules',
                desc: 'Molecules',
                disable: true
            }
            ],
            calculationPrecision: ref('PBE DFT'),
            calculationsPrecisionOptions: [{
                id: 'Semiempirical',
                desc: 'Low (Semiempirical)',
                disable: true,
            },
            {
                default: true,
                id: 'PBE DFT',
                desc: 'Medium (PBE DFT)'
            },
            {
                id: 'Hybrid DFT',
                desc: 'High (Hybrid DFT)',
                disable: true,
            }
            ]
        }
    },
    computed: {
        calculationsTypes() {
            const qwe = [...this.calculationsTypesStore.entitiesList]
            qwe.sort((a, b) => a.order - b.order)
            return qwe
        },
        disableForm() {
            return Object.values(this.calculationFormData).map(calcType => !!calcType.calculation).reduce((accumulator, currentValue) => {
                return accumulator && currentValue
            }, true)
        }
    },
    methods: {
        setAllTasks(value) {
            this.calculationsTypesStore.entitiesList.filter(calcType => !this.calculationFormData[calcType.type]?.calculation).forEach((calcType) => this.calculationFormData[calcType.type].use = value)
        },
        getCalculationByType(states, calcType) {
            return this.chemicalCard?.calculations
                .find(element => (element.calculation_type == calcType.type) && (states.indexOf(element.state) !== -1))
        },
        getCurrentCalculation(calcType) {
            return this.getCalculationByType([CALCULATION_STATES.RUN, CALCULATION_STATES.QUEUED, CALCULATION_STATES.SUCCESS], calcType)
        },
        submitForm() {
            const calculations = Object.values(this.calculationFormData).filter(data => data.use && !data.calculation).map(data => {
                return {
                    calculation_task_type_id: data.id,
                    extra_kwargs: {}
                }
            })
            this.calculationsStore.addBatch({
                calculations: calculations,
                calculation_structure_type: this.calculationStructureType,
                calculation_precision: this.calculationPrecision,
                chemical_card_id: this.chemCardId
            }
            ).then((data) => {
                Object.assign(this.chemicalCardsStore.getById(this.chemCardId), { ...this.chemicalCardsStore.getById(this.chemCardId), ...data })
                this.$emit('close')
            })
        },
        calculationFormDataHandler(calcTypeEvent, value) {
            console.log(calcTypeEvent,value)
            this.calculationFormData[calcTypeEvent.type].use = value
            if (value) {
                calcTypeEvent.dependencies.forEach(dep => {
                    const depType = this.calculationsTypesStore.getById(dep)
                    this.calculationFormData[depType.type].use = true
                    this.calculationFormDataHandler(depType, true)
                })
            } else {
                this.calculationsTypes
                .filter(calcType => (calcType.dependencies || []).indexOf(calcTypeEvent.id) != -1 && !this.calculationFormData[calcTypeEvent.type].calculation)
                .forEach((calcType) => {
                    const oldValue = this.calculationFormData[calcType.type].use
                    const depValue = calcType.dependencies.map(d => this.calculationFormData[this.calculationsTypesStore.getById(d).type].use).reduce((accumulator, currentValue) => {
                        return accumulator && currentValue
                    }, true)
                    this.calculationFormDataHandler(calcType,
                        oldValue && depValue
                    )
                })
            }
            
        },
    },
    mounted() {
        this.calculationsTypesStore.init().then(() => {
            console.log(this.calculationsTypesStore.entitiesList)
            this.calculationsTypesStore.entitiesList.forEach((calcType) => this.calculationFormData[calcType.type] = { use: true, calculation: this.getCurrentCalculation(calcType), id: calcType.id })
        })
    }
}
</script>
<style></style>