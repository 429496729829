<template>
    <q-card dense flat bordered>
        <q-card-section>
            <div class="row justify-between">
                <div class="text-h6">{{ billingHistory.type == 'payment' ? "Payment" : "Deposit" }} # {{ billingHistory.id }}
                </div>
                <div class="text-h5">{{ billingHistory.total_sum }} {{ billingHistory.total_sum_currency }}</div>

            </div>
            <div class="text-subtitle2">{{ getDateView(billingHistory.created_at) }}</div>
            <q-btn flat type="a" color="primary" :to="{ name: 'chemical_cards_info', params: { id: billingHistory.calculation.chemical_card_local_user_id } }"
                v-if="billingHistory.type == 'payment'" class="text-subtitle2">[Calculation # {{
                    billingHistory.calculation.local_user_id || billingHistory.calculation }}]</q-btn>
            
        </q-card-section>
    </q-card>
</template>

<script>
import { getDateView } from '@/shared/translate'
export default {
    props: {
        billingHistory: {
            require: true,
            type: Object
        }
    },
    methods: {
        getDateView
    }
}
</script>

<style></style>