const SYMMETRY_GROUPS_SHORT_NAMES = {
  1: "P1",
  2: 'P<span style="text-decoration:overline;">1</span>',
  3: "P3",
  4: "P2<sub>1</sub>",
  5: "C2",
  6: "Pm",
  7: "Pc",
  8: "Cm",
  9: "Cc",
  10: "P2/m",
  11: "P2<sub>1</sub>/m",
  12: "C2/m",
  13: "P2/c",
  14: "P2<sub>1</sub>/c",
  15: "C2/c",
  16: "P222",
  17: "P222<sub>1</sub>",
  18: "P2<sub>1</sub>2<sub>1</sub>2",
  19: "P2<sub>1</sub>2<sub>1</sub>2<sub>1</sub>",
  20: "C222<sub>1</sub>",
  21: "C222",
  22: "F222",
  23: "I222",
  24: "I2<sub>1</sub>2<sub>1</sub>2<sub>1</sub>",
  25: "Pmm2",
  26: "Pmc2<sub>1</sub>",
  27: "Pcc2",
  28: "Pma2",
  29: "Pca2<sub>1</sub>",
  30: "Pnc2",
  31: "Pmn2<sub>1</sub>",
  32: "Pba2",
  33: "Pna2<sub>1</sub>",
  34: "Pnn2",
  35: "Cmm2",
  36: "Cmc2<sub>1</sub>",
  37: "Ccc2",
  38: "Amm2",
  39: "Aem2",
  40: "Ama2",
  41: "Aea2",
  42: "Fmm2",
  43: "Fdd2",
  44: "Imm2",
  45: "Iba2",
  46: "Ima2",
  47: "Pmmm",
  48: "Pnnn",
  49: "Pccm",
  50: "Pban",
  51: "Pmma",
  52: "Pnna",
  53: "Pmna",
  54: "Pcca",
  55: "Pbam",
  56: "Pccn",
  57: "Pbcm",
  58: "Pnnm",
  59: "Pmmn",
  60: "Pbcn",
  61: "Pbca",
  62: "Pnma",
  63: "Cmcm",
  64: "Cmca",
  65: "Cmmm",
  66: "Cccm",
  67: "Cmma",
  68: "Ccca",
  69: "Fmmm",
  70: "Fddd",
  71: "Immm",
  72: "Ibam",
  73: "Ibca",
  74: "Imma",
  75: "P4",
  76: 'P4<sub>1</sub>',
  77: 'P4<sub>2</sub>',
  78: 'P4<sub>3</sub>',
  79: 'I4',
  80: 'I4<sub>1</sub>',
  81: 'P<span style="text-decoration:overline;">4</span>',
  82: 'I<span style="text-decoration:overline;">4</span>',
  83: 'P4/m',
  84: 'P4<sub>2</sub>/m',
  85: 'P4/n',
  86: 'P4<sub>2</sub>/m',
  87: 'I4/m',
  88: 'I4<sub>1</sub>/a',
  89: 'P422',
  90: 'P42<sub>1</sub>2',
  91: 'P4<sub>1</sub>22',
  92: 'P4<sub>1</sub>2<sub>1</sub>2',
  93: 'P4<sub>2</sub>22',
  94: 'P4<sub>2</sub>2<sub>1</sub>2',
  95: 'P4<sub>3</sub>22',
  96: 'P4<sub>3</sub>2<sub>1</sub>2',
  97: 'I422',
  98: 'I4<sub>1</sub>22',
  99: 'P4mm',
  100: 'P4bm',
  101: 'P4<sub>2</sub>cm',
  102: 'P4<sub>2</sub>nm',
  103: 'P4cc',
  104: 'P4nc',
  105: 'P4<sub>2</sub>mc',
  106: 'P4<sub>2</sub>bc',
  107: 'I4mm',
  108: 'I4cm',
  109: 'I4<sub>1</sub>md',
  110: 'I4<sub>1</sub>cd',
  111: 'P<span style="text-decoration:overline;">4</span>2m',
  112: 'P<span style="text-decoration:overline;">4</span>2c',
  113: 'P<span style="text-decoration:overline;">4</span>2<sub>1</sub>m',
  114: 'P<span style="text-decoration:overline;">4</span>2<sub>1</sub>c',
  115: 'P<span style="text-decoration:overline;">4</span>m2',
  116: 'P<span style="text-decoration:overline;">4</span>c2',
  117: 'P<span style="text-decoration:overline;">4</span>b2',
  118: 'P<span style="text-decoration:overline;">4</span>n2',
  119: 'I<span style="text-decoration:overline;">4</span>m2',
  120: 'I<span style="text-decoration:overline;">4</span>c2',
  121: 'I<span style="text-decoration:overline;">4</span>2m',
  122: 'I<span style="text-decoration:overline;">4</span>2d',
  123: 'I<span style="text-decoration:overline;">4</span>P4/mmm',
  124: 'I<span style="text-decoration:overline;">4</span>P4/mmc',
  125: 'I<span style="text-decoration:overline;">4</span>P4/nbm',
  126: 'I<span style="text-decoration:overline;">4</span>P4/nnc',
  127: 'I<span style="text-decoration:overline;">4</span>P4/mbm',
  128: 'I<span style="text-decoration:overline;">4</span>P4/mnc',
  129: 'I<span style="text-decoration:overline;">4</span>P4/nmm',
  130: 'I<span style="text-decoration:overline;">4</span>P4/ncc',
  131: 'P4<sub>2</sub>/mmc',
  132: 'P4<sub>2</sub>/mcm',
  133: 'P4<sub>2</sub>/nbc',
  134: 'P4<sub>2</sub>/nnm',
  135: 'P4<sub>2</sub>/mbc',
  136: 'P4<sub>2</sub>/mnm',
  137: 'P4<sub>2</sub>/nmc',
  138: 'P4<sub>2</sub>/ncm',
  139: 'I4/mmm',
  140: 'I4/mcm',
  141: 'I4<sub>1</sub>/amd',
  142: 'I4<sub>1</sub>/acd',
  143: 'P3',
  144: 'P3<sub>1</sub>',
  145: 'P3<sub>2</sub>',
  146: 'R3',
  147: 'P<span style="text-decoration:overline;">3</span>',
  148: 'R<span style="text-decoration:overline;">3</span>',
  149: 'P312',
  150: 'P321',
  151: 'P3<sub>1</sub>12',
  152: 'P3<sub>1</sub>21',
  153: 'P3<sub>2</sub>12',
  154: 'P3<sub>2</sub>21',
  155: 'R32',
  156: 'P3m1',
  157: 'P31m',
  158: 'P3c1',
  159: 'P31c',
  160: 'R3m',
  161: 'P3c1',
  162: 'P<span style="text-decoration:overline;">3</span>1m',
  163: 'P<span style="text-decoration:overline;">3</span>1c',
  164: 'P<span style="text-decoration:overline;">3</span>m1',
  165: 'P<span style="text-decoration:overline;">3</span>c1',
  166: 'R<span style="text-decoration:overline;">3</span>m',
  167: 'R<span style="text-decoration:overline;">3</span>c',
  168: 'P6',
  169: 'P6<sub>1</sub>',
  170: 'P6<sub>5</sub>',
  171: 'P6<sub>2</sub>',
  172: 'P6<sub>4</sub>',
  173: 'P6<sub>3</sub>',
  174: 'P<span style="text-decoration:overline;">6</span>',
  175: 'P6/m',
  176: 'P6<sub>3</sub>/m',
  177: 'P622',
  178: 'P6<sub>1</sub>22',
  179: 'P6<sub>5</sub>22',
  180: 'P6<sub>2</sub>22',
  181: 'P6<sub>4</sub>22',
  182: 'P6<sub>3</sub>22',
  183: 'P6mm',
  184: 'P6cc',
  185: 'P6<sub>3</sub>cm',
  186: 'P6<sub>3</sub>mc',
  187: 'P<span style="text-decoration:overline;">6</span>m2',
  188: 'P<span style="text-decoration:overline;">6</span>c2',
  189: 'P<span style="text-decoration:overline;">6</span>2m',
  190: 'P<span style="text-decoration:overline;">6</span>2c',
  191: 'P6/mmm',
  192: 'P6/mcc',
  193: 'P6/mcm',
  194: 'P6/mmc',
  195: 'P23',
  196: 'F23',
  197: 'I23',
  198: 'P2<sub>1</sub>3',
  199: 'I2<sub>1</sub>3',
  200: 'Pm<span style="text-decoration:overline;">3</span>',
  201: 'Pn<span style="text-decoration:overline;">3</span>',
  202: 'Fm<span style="text-decoration:overline;">3</span>',
  203: 'Fd<span style="text-decoration:overline;">3</span>',
  204: 'Im<span style="text-decoration:overline;">3</span>',
  205: 'Pa<span style="text-decoration:overline;">3</span>',
  206: 'Ia<span style="text-decoration:overline;">3</span>',
  207: 'P432',
  208: 'P4<sub>2</sub>32',
  209: 'F432',
  210: 'F4<sub>1</sub>32',
  211: 'I432',
  212: 'P4<sub>3</sub>32',
  213: 'P4<sub>1</sub>32',
  214: 'I4<sub>1</sub>32',
  215: 'P<span style="text-decoration:overline;">4</span>3m',
  216: 'F<span style="text-decoration:overline;">4</span>3m',
  217: 'I<span style="text-decoration:overline;">4</span>3m',
  218: 'P<span style="text-decoration:overline;">4</span>3m',
  219: 'F<span style="text-decoration:overline;">4</span>3c',
  220: 'I<span style="text-decoration:overline;">4</span>3d',
  221: 'Pm<span style="text-decoration:overline;">3</span>m',
  222: 'Pn<span style="text-decoration:overline;">3</span>n',
  223: 'Pm<span style="text-decoration:overline;">3</span>n',
  224: 'Pn<span style="text-decoration:overline;">3</span>m',
  225: 'Fm<span style="text-decoration:overline;">3</span>m',
  226: 'Fm<span style="text-decoration:overline;">3</span>c',
  227: 'Fd<span style="text-decoration:overline;">3</span>m',
  228: 'Fd<span style="text-decoration:overline;">3</span>c',
  229: 'Im<span style="text-decoration:overline;">3</span>m',
  230: 'Ia<span style="text-decoration:overline;">3</span>d',
};

export { SYMMETRY_GROUPS_SHORT_NAMES };
