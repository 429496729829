import { defaultStore } from './defaultStore';
import { defineStore } from "pinia";
import {apiClient} from "../api";


const CalculationsActions = {
    async addBatch(entitiesData) {
        // let result = []
        let response = await apiClient.post('calculations/calculations_batch/', entitiesData);
        if (response.status >= 200 && response.status < 300) {
            return response.data
          } else {
            throw new Error(response.error);
        }
        // return result

    }
}


export default defineStore('calculations', defaultStore('calculations/calculations/', {}, {},CalculationsActions));
