<template>
  <q-layout view="lHh Lpr lFf">
    <q-header class="">
      <q-toolbar>
        <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" icon="menu" />
        <q-toolbar-title>
          MatSolver
        </q-toolbar-title>
        <div>MatSolver v1.0.0alpha</div>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" show-if-above bordered class="bg-dark">
      <q-list class="text-white">
        <q-item-label class="text-white" header>Menu</q-item-label>
        <q-item active-class="active-tab" v-if="userStore.user" to="/materials" exact>
          <q-item-section avatar>
            <q-icon name="receipt_long" />
          </q-item-section>
          <q-item-section>
            <q-item-label>My materials</q-item-label>
          </q-item-section>
        </q-item>
        <q-item active-class="active-tab" v-if="userStore.user" to="/chemical_cards/add" exact>
          <q-item-section avatar>
            <q-icon name="receipt_long" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Add new material</q-item-label>
          </q-item-section>
        </q-item>
        <q-item active-class="active-tab" v-if="userStore.user" to="/calculations" exact>
          <q-item-section avatar>
            <q-icon name="calculate" />
          </q-item-section>
          <q-item-section>
            <q-item-label>My Calculations</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="userStore.user" active-class="active-tab" to="/account" exact>
          <q-item-section avatar>
            <q-icon name="settings" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Settings</q-item-label>
          </q-item-section>
        </q-item>

        <q-item active-class="active-tab" v-if="userStore.user" to="/user_billing" exact>
          <q-item-section avatar>
            <q-icon name="wallet" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Balance {{ userStore.user.user_billing.balance }} {{
              userStore.user.user_billing.balance_currency }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item active-class="active-tab" v-if="!userStore.user" to="/auth" exact>
          <q-item-section avatar>
            <q-icon name="person" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Login/Register</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="userStore.user" active-class="active-tab" clickable @click="logout">
          <q-item-section avatar>
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Exit</q-item-label>
          </q-item-section>
        </q-item>

        <q-item-label class="text-white" header>Links</q-item-label>
        <q-item active-class="active-tab" clickable tag="a" target="_blank" href="https://wiki.storion.ru/soft/matsolver">
          <q-item-section avatar>
            <q-icon name="school" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Wiki Docs</q-item-label>
            <q-item-label class="text-white" caption>FAQ</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <div v-if="!userStore.isInit" class="q-pa-md justify-start">
            <q-circular-progress indeterminate rounded size="50px" color="primary" class="q-ma-md" />
        </div>
      <router-view v-if="userStore.isInit" />
      <dialog-popup></dialog-popup>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import { useUserStore } from "../store/user";
import router from "@/router";
import DialogPopup from '@/components/DialogPopup.vue';


export default {
  components: { DialogPopup },
  name: 'LayoutDefault',
  setup() {
    return {
      leftDrawerOpen: ref(false),
      createCalculationFormShow: ref(false),
      userStore: useUserStore(),
    }
  },
  methods: {
    logout() {
      this.userStore.logout().then(() => {
        router.push("/auth") 
        window.location.reload();})
    }
  },
  created() {
      this.userStore.initializeStore().then(() => {
      if (!this.userStore.user)
      {
        if (this.$route.name != 'auth') router.push("/auth")
      } else {
    
        if (!this.$route.name) router.push("/materials")
    }
    })
  }
}
</script>
<style scoped>
.active-tab {
  background-color: #1976d2;
}
</style>
