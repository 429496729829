<template>
    <div class="row">
        <div v-if="!calculationsStore.isInit" class="q-pa-md flex flex-center">
            <q-circular-progress indeterminate rounded size="50px" color="primary" class="q-ma-md" />
        </div>
        <q-table v-if="calculationsStore.isInit" style="width: 100%" flat bordered title="My Calculations"
            :rows="getFiltered" :columns="columns" :visible-columns="visibleColumns"
            :rows-per-page-options="[10, 15, 20, 25, 50, 0]" :pagination="{rowsPerPage: 50}" row-key="name">
            <template v-slot:top="props">
                <div class="col-2 q-table__title">My Calculations</div>
                <q-space />
                <q-select v-model="visibleColumns" multiple outlined dense options-dense display-value="Select columns"
                    emit-value map-options :options="columns" option-value="name" options-cover style="min-width: 150px" />


                <q-btn flat dense :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
                    @click="props.toggleFullscreen" class="q-ml-md" />
            </template>
            <template v-slot:top-right>
                <q-btn @click="clearFilter()" flat label="Clear Filter" icon="clear" />
            </template>
            <template v-slot:body="props">
                <q-tr class="text-gray" :style="{'cursor': 'pointer', 'background-color': changeAlpha(getPaletteColor(colors[props.row.state]), 0.48)}" :props="props" @click="onRowClick(props.row)">
                    <q-td key="calculation_id" :props="props">
                        {{ props.row.id }}
                    </q-td>
                    <q-td key="chemical_formula" :props="props" v-html="formulaView(props.row.chemical_card.chemical_formula)">
                    </q-td>
                    <q-td key="state" :props="props">
                        {{ props.row.state }}
                    </q-td>
                    <q-td key="chemical_card_id" :props="props">
                        {{ props.row.chemical_card.id }}
                    </q-td>
                    <q-td key="calculation_type" :props="props">
                        {{ props.row.calculation_type }}
                    </q-td>
                    
                    <q-td key="duration" :props="props">
                        {{ props.row.duration }}
                    </q-td>
                    <q-td key="total_cost" :props="props">
                        {{ props.row.total_cost }} {{ props.row.total_cost_currency }}
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:bottom-row>
                <q-tr no-hover>
                    <q-th v-for="col in columns.filter(col => col && visibleColumns.indexOf(col.name) != -1)"
                        :key="col.name" class="text-italic text-purple">
                        <q-input :debounce="1000" v-model="filtersInputes[col.field]" dense :label="col.label"></q-input>
                    </q-th>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>

<script>
import useCalculationsStore from '../store/calculations'
import { ref } from 'vue'

import { formulaView } from '@/shared/translate'
import { getPaletteColor, changeAlpha } from '@/shared/colors'
import { COLOR_NAME_BY_STATE } from '@/shared/calculations'
export default {
    //   components: { MaterialCard },
    setup() {
        const columns = [
            { name: 'calculation_id', align: 'left', label: 'Calculation ID', field: 'id', sortable: true },
            { name: 'chemical_formula', align: 'left', label: 'Formula', field: 'chemical_formula', sortable: true },
            { name: 'state', align: 'left', label: 'State', field: 'state', sortable: true },
            { name: 'chemical_card_id', align: 'left', label: 'Chemical ID', field: 'chemical_card_id', sortable: true },
            { name: 'calculation_type', align: 'left', label: 'Calculation Type', field: 'calculation_type', sortable: true },
           
            { name: 'duration', align: 'left', label: 'Duration', field: 'duration', sortable: true },
            { name: 'total_cost', align: 'left', label: 'Costs', field: 'total_cost', sortable: true },
            
        ]
        let defaultVisible = columns.filter(el => !el.unshow).map(col => col.name)
        let visibleColumns = ref(JSON.parse(localStorage.getItem("calculations_visible_columns")) || defaultVisible)
        return {
            colors: COLOR_NAME_BY_STATE,
            getPaletteColor: getPaletteColor,
            changeAlpha: changeAlpha || ((q) => q),
            formulaView: formulaView,
            filtersInputes: ref({
                id: null,
                chemical_formula: null,
                calculation_id: null,
                calculation_type: null,
                state: null,
                chemical_card_id: null,
                duration: null,
                costs: null,

            }),
            columns: ref(columns),
            visibleColumns: visibleColumns,
            calculationsStore: useCalculationsStore()
        }
    },
    watch: {
        visibleColumns: {
            handler() {
                this.visibleColumns.value = this.columns.map((el) => el.field).filter((el) => this.visibleColumns.indexOf(el != -1))
                this.saveSettings()
            }
        }
    },
    methods: {
        saveSettings() {
            localStorage.setItem("calculations_visible_columns", JSON.stringify(this.visibleColumns))
        },
        onRowClick(entity) {
            this.$router.push({ name: 'chemical_cards_info', params: { id: entity.chemical_card.local_user_id } })
        },
        clearFilter() {
            Object.keys(this.filtersInputes).forEach(key => this.filtersInputes[key] = null)
        },
    },
    computed: {
        sortedColumns() {
            return this.columns.map((el) => el.field).filter((el) => this.visibleColumns.indexOf(el != -1))
        },
        getFiltered() {
            return this.getRows.filter((entity) => {
                let isTarget = Object.keys(this.filtersInputes).map(field => {
                    let filterInputsValue = this.filtersInputes[field]
                    if (filterInputsValue) {
                        let entityValue = entity[field]
                        if (!(typeof (entityValue) == 'boolean')) {
                            entityValue = JSON.stringify(entityValue)
                        } else if (!(typeof (entityValue) == 'string')) {
                            entityValue = entityValue ? 'yes' : 'no'
                        }
                        if (!(typeof (filterInputsValue) == 'string')) filterInputsValue = JSON.stringify(filterInputsValue)
                        return !((entityValue != null ? entityValue : "").toLowerCase().indexOf(filterInputsValue.toLowerCase()) == -1)
                    }
                    return true
                }).reduce((isTarget, current) => isTarget && current, true)
                return isTarget
            })
        },
        getRows() {
            return this.calculationsStore.entitiesList.map(entity => {
                return {...entity, chemical_card_id: entity.chemical_card.id, chemical_formula: entity.chemical_card.chemical_formula}})
        }
    },
    created() {
        this.calculationsStore.init()
    }
}
</script>

<style>
.bg-red{
     background: #ff7066 !important;
}
</style>