<template>
  <div class="col-xs-12 col-md-3">
    <div class="row q-my-lg q-mx-sm">
      <div class="q-my-auto text-h5" v-html="cardLabel">
      </div>
      <div v-if="!chemicalCard.parent && !chemicalCard.material_project_id">
        <q-btn no-caps flat color="primary" @click="downloadProvidedFile()">External structure file</q-btn>
      </div>
      <div v-if="chemicalCard.parent">
        <q-btn flat color="primary" @click="goToParent()">From Chemical Card {{ chemicalCard.parent }}</q-btn>
      </div>
      <div v-if="chemicalCard.material_project_id">
        <q-btn flat type="a" disable color="primary" class="text-subtitle2">
          Materials Project ID: {{ chemicalCard.material_project_id }}
        </q-btn>
      </div>
    </div>

    <q-card flat bordered class="q-my-sm col-12">
      <q-expansion-item default-opened>
        <template v-slot:header>
          <q-item-section avatar>
            <q-avatar icon="menu" color="white" text-color="dark" />
          </q-item-section>

          <q-item-section>
            Actions
          </q-item-section>

        </template>
        <q-card-actions class="row justify-between q-mx-sm">
          <q-dialog v-model="dialog" position="top" dense class="q-my-sm" popup default-opened icon="calculate"
            label="Create Calculation" caption="Create Calculation Form">
            <div class="colum content-arround">
              <div class="col-12 q-pa-sm">
                <calculation-menu-form @close="dialog = false" v-if="chemicalCard"
                  :chem-card-id="chemicalCard.id"></calculation-menu-form>
              </div>
            </div>
          </q-dialog>
          <q-btn no-caps outline dense v-if="userStore.user.user_billing.balance > 0" unelevated="" label="Calculate"
            color="primary" @click="dialog = true" icon="calculate" />
          <q-btn dense outline :to="{ name: 'user_billing' }" v-else color="warning" unelevated="" icon="add_card" label="Negative
            Balance" />
          <q-btn outline no-caps dense unelevated="" color="negative" @click="deleteCard" icon="delete"
            label="Delete card" />
        </q-card-actions>
      </q-expansion-item>
      <entity-tags :tags="chemicalCard.tags" @tagsUpdated="updateTags($event)"></entity-tags>
    </q-card>

    <div class="col-12">
      <q-list bordered padding>
        <q-item>
          <q-item-section>
            <q-item-label>Chemical Card Data</q-item-label>
            <q-item-label caption>Select Provided Structure or calculations from list. Click to Activate.</q-item-label>
          </q-item-section>
        </q-item>

        <q-separator spaced />
        <!-- <q-item-label header>Provided:</q-item-label> -->
        <q-item clickable @click="enableProvided = !enableProvided" style="cursor: pointer" :active="enableProvided"
          active-class="text-primary bg-blue-2">

          <q-item-section>
            Provided structure</q-item-section>

        </q-item>
        <q-item-label header>Calculations:
          <q-select dense rounded outlined clearable color="primary" multiple v-model="filterByState"
            :options="statesNames" label="State" />
        </q-item-label>
        <q-item clickable v-for="calculation in calculationsList" :key="calculation.id"
          :active="selectedCalculation && selectedCalculation.id == calculation.id"
          @click="clickCalculationItem(calculation)" style="cursor: pointer" active-class="text-primary bg-blue-2">

          <q-item-section>
            <q-item-label>{{ calculation.calculation_name }}</q-item-label>
            <q-item-section caption lines="2">
              Calculation # {{ calculation.local_user_id }}
            </q-item-section>
          </q-item-section>

          <q-item-section side>

            <q-icon outline unelevated size="md" round :color="colors[calculation.state]"
              :name="icons[calculation.state]">
              <q-tooltip>
                {{ calculation.state }}
              </q-tooltip>
            </q-icon>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>

  <div  class="col-xs-12 col-md-9 row q-px-sm">
    <div class="col-12" v-if="selectedCalculation">
      <calculation-expanded-item :calculation="selectedCalculation"></calculation-expanded-item>
    </div>
    <div v-if="enableProvided && provided" class="col-12">
      <q-card bordered flat>
        <q-expansion-item switch-toggle-side bordered expand-separator toggle-aria-label="Show detail">
          <template v-slot:header>
            <div class="text-h6">
              Provided Structure
            </div>
          </template>
          <q-card bordered class="col-12 q-my-sm" flat>
            <q-card-section>
              <compound-view v-if="chemicalCard && provided" :showDownloadButton="true" :showJmol=true
                :compound="provided"></compound-view>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </q-card>
    </div>
  </div>
</template>

<style></style>

<script>
import useChemicalCardsStore from '../store/chemical_cards'
import { useUserStore } from '../store/user'
import { useTagsStore } from '../store/tags'
import CompoundView from './CompoundView.vue'
import CalculationExpandedItem from './CalculationExpandedItem.vue'
import CalculationMenuForm from './CalculationMenuForm.vue'
import EntityTags from './TagsEntity.vue'
import { COLOR_NAME_BY_STATE, ICON_NAME_BY_STATE, CALCULATION_STATES, } from '@/shared/calculations'
import { ref } from 'vue'
export default {
  components: { CompoundView, CalculationMenuForm, EntityTags, CalculationExpandedItem },
  props: {
    chemicalCard: {
      require: true,
      type: Object
    }
  },
  setup() {
    return {
      filterByState: ref([]),
      states: CALCULATION_STATES,
      statesNames: Object.values(CALCULATION_STATES),
      colors: COLOR_NAME_BY_STATE,
      icons: ICON_NAME_BY_STATE,
      selectedCalculation: ref(),
      enableProvided: ref(true),
      tab: ref('provided'),
      dialog: ref(false),
      chemicalCardsStore: useChemicalCardsStore(),
      tagsStore: useTagsStore(),
      userStore: useUserStore(),
    }
  },
  methods: {
    clickCalculationItem(calculation) {
      const oldId = this.selectedCalculation?.id
      this.selectedCalculation = null
      !this.selectedCalculation || oldId != calculation.id ? this.selectedCalculation = calculation : this.selectedCalculation = null
    },
    updateTags(tags) {
      this.chemicalCardsStore.update(this.chemicalCard.id, { tags: tags })
    },
    deleteCard() {
      if (confirm("Delete this card?")) {
        this.chemicalCardsStore.delete(this.chemicalCard.id).then(() =>
          this.$router.push({ name: 'materials' })
        )
      }
    },
    goToParent() {
      this.$router.push({ name: 'chemical_cards_info', params: { id: this.chemicalCard.parent } })
    },
    downloadProvidedFile() {
      window.open(this.chemicalCard.provided.poscar_file || this.chemicalCard.provided.cif_file, '_blank',)
    }
  },
  computed: {
    calculationsList() {
      return (this.chemicalCard ? (this.chemicalCard.calculations || []) : []).filter(calc => !this.filterByState || !this.filterByState.length || (this.filterByState && this.filterByState.indexOf(calc.state) != -1)).sort((a, b) => b.id - a.id)
    },
    provided() {
      return this.chemicalCard ? this.chemicalCard.provided : null
    },
    calculated() {
      if (!this.chemicalCard) return null
      if (this.chemicalCard.calculation_opt_full) {
        return this.chemicalCard.calculation_opt_full.end
      } else if (this.chemicalCard.calculation_opt_ions) {
        return this.chemicalCard.calculation_opt_ions.end
      }
      return null
    },
    cardLabel() {
      if (!this.provided) {
        return ""
      }
      const DIGIT_EXPRESSION = /^\d$/;
      const formula = this.provided.chemical_formula.split("").map(ch => DIGIT_EXPRESSION.test(ch) ? `<sub>${ch}</sub>` : ch).join("")
      return `${formula} ID: ${this.chemicalCard.local_user_id}`
    },
  },
  mounted() {
    this.tagsStore.init()
  }
}
</script>
<style scoped></style>
 