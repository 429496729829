import { defineStore } from "pinia";


export const useDialogPopupStore = defineStore('dialogPopup', {
  state: () => ({
    show: false,
    message: "",
    data: null
  }),
  getters: {},
  actions: {
    showAlertMessage(alertMessage) {
      if (alertMessage)  {
        this.message = alertMessage
        this.show = true
        setTimeout(() => { this.show = false; this.message = ''; }, 100000)
      }
    },
    showAlertData(data) {
      if (data) {
        this.show = true
        this.data = data
        setTimeout(() => { this.show = false; this.data = null }, 100000)
    }
    },
    showAlertFromHTTPError(error) {
      console.log(error)
      if (error.response.data && typeof(error.response.data) == 'object') {
        this.showAlertData(error.response.data)
      } else {
        this.showAlertMessage(`Status Code:${error.response.status}\n${JSON.stringify(error.response.status < 500 ? error.response.data : '')}`)
      }
      
    }
  }
});
