<template>
  <q-page class="q-pa-md row justify-center">
    <create-compound-form></create-compound-form>
  </q-page>
</template>

<style></style>

<script>
import CreateCompoundForm from '../components/CreateCompoundForm.vue'

export default {
  components: { CreateCompoundForm },
}
</script>
