<template>
    <div class="row justify-between items-start">
        <div class="row">
            <div class="col-12 row q-mx-xs">
                <table style="width: 50%;" cols="12">
                    <tr class="col-12" v-for="item, index in (viewData || []).filter(data => data.value != null)"
                        :key="index">
                        <td colspan="4" class="text-bold">
                            {{ item.label }}:
                        </td>
                        <td style="max-width: 500px;">
                            {{ item.value }}
                        </td>
                    </tr>

                    <tr v-if="compound.elastic_tensor">
                        <td colspan="4" class="text-bold">
                            Elastic Tensor, GPa:
                        </td>
                        <td>
                            <table class="matrix">
                                <tr v-for="row, i in (compound.elastic_tensor || [])" :key="i">
                                    <td v-for="val, j in row" :key="j">
                                        {{ val.toFixed(1) }}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr v-if="typeof (compound.is_mechanically_stable) == 'boolean'">
                        <td colspan="4" class="text-bold">
                            Mechanically Stable?, GPa:
                        </td>
                        <td>
                            {{ compound.is_mechanically_stable ? 'yes' : 'no' }}
                            <i>
                                {{ compound.degree_mechanical_stability ? compound.degree_mechanical_stability.toFixed(1)
                                    : ""
                                }}</i>
                        </td>
                    </tr>
                    <tr v-if="typeof (compound.is_dynamically_stable) == 'boolean'">
                        <td colspan="4" class="text-bold">
                            Dynamically Stable?, THz:
                        </td>
                        <td>
                            {{ compound.is_dynamically_stable ? 'yes' : 'no' }}
                            <i>{{ compound.degree_dynamical_stability ? compound.degree_dynamical_stability.toFixed(1) : ""
                            }}</i>
                        </td>
                    </tr>
                    <tr v-if="compound.elastic_tensor_eigen">
                        <td colspan="4" class="text-bold">
                            Elastic Tensor Eigenvalues, GPa:
                        </td>
                        <td>
                            {{ `[${(compound.elastic_tensor_eigen || []).map(el => el.toFixed(1)).join(", ")}]` }}
                        </td>
                    </tr>
                </table>
                <div v-if="compound.filename_phonon_frequencies" class="col-6 q-pa-sm">
                    <q-img style="max-width: 400px" :src="`${host}${files[compound.filename_phonon_frequencies]}`">
                        <template v-slot:error>
                        </template>
                    </q-img>
                </div>
                <div v-if="compound.filename_volumescan" class="col-6 q-pa-sm">
                    <q-img style="max-width: 400px" :src="`${host}${files[compound.filename_volumescan]}`">
                        <template v-slot:error>
                            <div class="absolute-full flex flex-center bg-negative text-white">
                                Cannot load image
                            </div>
                        </template>
                    </q-img>
                </div>
            </div>
            <div class="row items-start">
                <div class="col-xs-12 col-md-4 col-lg-5 row items-start">
                    <div class="col-12 q-ma-sm row">
                        <div class="col-4 text-bold">
                            Symetry group
                        </div>
                        <div class="col-8" :id="'symmetryViewDiv_' + compound.id" :ref="'symmetryViewDiv_' + compound.id"
                            v-html="shortNameGroupHTML">
                        </div>
                        <div class="col-4 text-bold">
                            Cell volume
                        </div>
                        <div class="col-8">
                            {{ compound.volume.toFixed(2) }} Å<sup>3</sup>
                        </div>
                        <div class="col-4 text-bold">
                            Number of atoms&nbsp;
                        </div>
                        <div class="col-8">
                            {{ compound.number_of_atoms }}
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="col-12 q-pa-sm">
                            <div class="text-bold">Lattice vectors, Å</div>
                            <simple-table :fixedFloat="3" :data="acell" :hideBottom="true"
                                :headers="[{ label: 'R1', name: 'R1', field: (el => el), align: 'left' }, { label: 'R2', name: 'R2', field: (el => el), align: 'left' }, { label: 'R3', name: 'R3', field: (el => el), align: 'left' }]"></simple-table>
                        </div>
                        <div class="col-12 q-pa-sm">
                            <div class="text-bold">
                                Lattice angles, degrees
                            </div>
                            <simple-table :fixedFloat="3" :data="angels" :hideBottom="true"
                                :headers="[{ label: 'α', name: 'X', field: (el => el), align: 'left' }, { label: 'β', name: 'Y', field: (el => el), align: 'left' }, { label: 'γ', name: 'Z', field: (el => el), align: 'left' }]">
                            </simple-table>
                        </div>
                    </div>

                    <div class="col-12 q-pa-sm">
                        <div class="text-bold">
                            Cartesian coordinates of atoms, Å
                        </div>
                        <simple-table v-if="xcart" :fixedFloat="5"
                            :headers="[{ label: 'Name', name: 'Name', field: (el => el), align: 'left' }, { label: 'X', name: 'X', field: (el => el), align: 'left' }, { label: 'Y', name: 'Y', field: (el => el), align: 'left' }, { label: 'Z', name: 'Z', field: (el => el), align: 'left' }]"
                            :data="xcart"></simple-table>
                    </div>
                </div>
                <div class="column col-xs-12 col-md-8 col-lg-7 q-px-sm">
                    <div v-if="showDownloadButton" class="q-my-xs self-end" style="width: 100%">
                        <load-structure-button style="width: 100%"
                            :source_files="{ poscar: compound.poscar_file, cif: compound.cif_file, xyz: compound.xyz_file }"></load-structure-button>
                    </div>
                    <div v-if="showJmol && source_file">
                        <compound-view-jmol :source_file="source_file"></compound-view-jmol>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { SYMMETRY_GROUPS_SHORT_NAMES } from '@/shared/consts'
import CompoundViewJmol from './CompoundViewJmol.vue'
import SimpleTable from './SimpleTable.vue'
import LoadStructureButton from './LoadStructureButton.vue'
export default {
    components: { CompoundViewJmol, SimpleTable, LoadStructureButton },
    props: {
        showJmol: {
            require: false,
            default: false
        },
        showDownloadButton: {
            require: false,
            default: false
        },
        jmol_file: {
            require: false
        },
        files: {
            require: false
        },
        compound: {
            type: Object,
            require: true
        },
    },
    computed: {
        acell() {
            return [[this.compound.acell_x, this.compound.acell_y, this.compound.acell_z]]
        },
        angels() {
            return [[this.compound.angle_x, this.compound.angle_y, this.compound.angle_z]]
        },
        xcart() {
            return (this.compound.xcart || []).map((r => [...r])).map(((element, index) => {
                element.unshift(this.compound.elements_names[index])
                return element
            }))
        },
        viewData() {
            return [{
                label: "Magnetic Moments, μB",
                value: (this.compound.magnetic_moments || []).length > 0 ? `[${(this.compound.magnetic_moments || []).map(el => el.toFixed(1)).join(", ")}]` : null
            },
            {
                label: "Atomic Oxidation",
                value: this.compound.atomic_oxidation
            },
            {
                label: "Total E, eV/at",
                value: this.compound.total_energy_per_atom ? this.compound.total_energy_per_atom.toFixed(5) : null
            },
            {
                label: "Bulk Modulus, GPa",
                value: this.compound.bulk_modulus ? this.compound.bulk_modulus.toFixed(0) : null
            },
            {
                label: "Stresses, GPa",
                value: (this.compound.stresses || []).length > 0 ? `[${(this.compound.stresses || []).map(el => el.toFixed(1)).join(", ")}]` : null
            },
            {
                label: "Phonon frequencies, THz",
                value: (this.compound.phonon_frequencies || []).length > 0 ? `[${(this.compound.phonon_frequencies || []).map(el => el.toFixed(3)).join(", ")}]` : null
            }]
        },
        host() {
            return `${window.location.protocol}//${window.location.host}`
        },
        shortNameGroupHTML() {
            return SYMMETRY_GROUPS_SHORT_NAMES[this.compound.symetry_group_number] + ", " + this.compound.symetry_group_number
        },
        source_file() {
            if (this.jmol_file) return this.jmol_file
            if (this.compound.poscar_file || this.compound.source_file || this.compound.cif_file || this.compound.xyz_file) {
                return this.compound.poscar_file || this.compound.source_file || this.compound.cif_file || this.compound.xyz_file
            }
            return null
        }
    },
    created() {
    }
}
</script>

<style scoped>
.matrix {
    text-align: center;
    font-family: monospace;
    font-size: 0.9rem;
    padding-left: 1px;
    border-image-slice: 22 22 22 22;
    border-image-width: 20px 20px 20px 20px;
    border-image-outset: 0px 0px 0px 0px;
    border-image-repeat: stretch stretch;
    border-image-source: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAABKCAYAAAA11z32AAAAAXNSR0IArs4c6QAAAJpJREFUaAXt2jEKg0AARUE3t9wT7jENFtO5IEhSPZsHRtkw/NKx1jqPm2vOOW5uv7q1O+vY/vDquOcvX+d/nj/+uyf7E2yTSIKAtokkCGibSIKAtokkCGibSIKAtokkCGibSIKAtokkCGibSIKAtokkCGibSIKAtokkCGibSIKAtokkCGibSIKAtokkCGibSIKAjt13Vv/82OsLo94UYanCbyYAAAAASUVORK5CYII=");
}

.matrix td {
    padding: 5px;
    text-align: center;
}
</style>