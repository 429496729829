<template>
    <!-- <div class=""> -->
    <q-card @click.stop flat bordered class="column justify-between">
        <q-expansion-item default-opened>
            <template v-slot:header>
          <q-item-section avatar>
            <q-avatar icon="tags" color="white" text-color="dark" />
          </q-item-section>

          <q-item-section>
            Tags
          </q-item-section>

        </template>
            <q-card-section>
            <q-select outlined use-input options-dense dense v-model="newTag" @update:model-value="addTag($event)"
            :options="tagsToAdd" option-value="id" option-label="text" @filter="filterFn" @input-value="newTagText = $event"
            @new-value="createTag()" ref="selectTag">
            <template v-slot:prepend>
                <q-icon size="20px" dense name="tag" />
            </template>
            <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps">
                    <q-chip icon="sell" outline class="q-ma-none">
                        {{ scope.opt.text }}
                    </q-chip>
                </q-item>
            </template>
            <template v-slot:append>
                <q-btn dense flat icon="add" @click.stop.prevent="createTag()" />
            </template>
        </q-select>
        </q-card-section>
        <q-card-actions>
            <TagChip class="q-my-xs" v-for="tag in tagsData" :key="tag" :tagID="tag.id" @remove="removeTag(tag)"></TagChip>
        </q-card-actions>
        </q-expansion-item>
            
            <!-- <q-separator spaced=""></q-separator> -->
       
           
    </q-card>
</template>

<script>
import { useTagsStore } from "@/store/tags";
import { ref } from "vue";
import TagChip from './TagChip';

export default {
    name: "EntityTags",
    props: {
        tags: {
            require: true,
            type: Array
        }
    },
    components: {
        TagChip
    },
    emits: [
        'tagsUpdated'
    ],
    setup(props) {
        const tagsStore = useTagsStore()
        tagsStore.init()
        return {
            tagsStore: tagsStore,
            newTag: ref(null),
            newTagText: ref(),
            tagsData: ref(props.tags.map(t => tagsStore.getById(t)))
        }

    },
    computed: {
        tagsToAdd() {
            return this.tagsStore.entitiesList.filter(tag => this.tagsIds.indexOf(tag.id) == -1) || []
        },
        tagsIds() {
            return this.tagsData.map(t => t.id)
        }
    },
    methods: {
        createTag() {
            this.tagsStore.add({ text: this.newTagText }).then(entity => {
                this.addTag(entity)
                this.newTagText = ""
            }).catch(() => { })
        },
        removeTag(tag) {
            const index = this.tagsData.findIndex(t => t.id == tag.id)
            if (index != -1) {
                this.tagsData.splice(index, 1)
                this.$emit("tagsUpdated", this.tagsIds);
            }
        },
        addTag(tag) {
            if (this.tagsIds.indexOf(tag.id) == -1) {
                this.tagsData.push(tag)
                this.$emit("tagsUpdated", this.tagsIds);
            }
            this.newTag = null;
            this.newTagText = ""
            this.$refs.selectTag.updateInputValue("", false)
        },
        filterFn(val, update) {
            if (val === '') {
                update(() => {
                    this.filteredTagsByInput = this.tagsToAdd
                })
                return
            }
            update(() => {
                const needle = val.toLowerCase()
                this.filteredTagsByInput = this.tagsToAdd.filter(v => v.text.toLowerCase().indexOf(needle) > -1)
            })
        }
    },
    created() {
        this.tagsStore.init()
    }
}
</script>

<style></style>