<template>
  <q-page padding>
    <p>This is an about page</p>
  </q-page>
</template>

<script>
export default {
  name: 'PageAbout'
}
</script>
