<template>
  <q-page class="row justify-center">
    <q-card flat style="width: 600px;">
      <q-card-section>
        <div class="text-h3 text-center">Welcome!</div>
        <div class="text-grey text-center">by MatSolver</div>
      </q-card-section>
      <div>
        <q-card flat bordered class="q-pa-sm">
          <div class="text-h6 text-primary">Reset Password</div>
          <q-form class="q-gutter-md q-mt-md" @submit="resetPasswordRequest">
            <q-input dense outlined clearable v-model="email" :rules="[val => !!val || 'Provide email', isValidEmail]"
              type="email" label="Email" />
            <div class="column justify-evenly items-start">
              <q-btn dense unelevated type="submit" color="primary-accent" size="lg" class="full-width"
                label="Request reset password" />
            </div>
          </q-form>
        </q-card>
      </div>
    </q-card>

  </q-page>
</template>

<script>
import { ref } from 'vue'
import { useDialogPopupStore } from "../store/dialog_popup";
import { apiClient } from '../api';
export default {
  setup() {
    return {
      dialogPopupStore: useDialogPopupStore(),
      email: ref(''),
      accept: ref(false),
      alert: ref(false),
      alertMessage: ref("")
    }
  },
  methods: {
    isValidEmail(val) {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || 'неверный формат почты';
    },
    showAlert(error) {
      this.dialogPopupStore.showAlertFromHTTPError(error)
    },
    resetPasswordRequest() {
      if (this.email) {
        apiClient.post('/auth/users/reset_password/', {
          email: this.email
        }).then(() => {
          this.$router.push({ name: 'auth' })
        }).catch((error) => {
          this.dialogPopupStore.showAlertFromHTTPError(error)
        })
      }
    },
  },
}
</script>