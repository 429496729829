import { defineStore } from "pinia";
import router from "../router";
import {apiClient} from "../api"


// function readCookie(name) {
//   var nameEQ = name + "=";
//   var ca = document.cookie.split(';');
//   for(var i=0;i < ca.length;i++) {
//       var c = ca[i];
//       while (c.charAt(0)==' ') c = c.substring(1,c.length);
//       if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
//   }
//   return null;
// }



export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    tokens: null,
    isInit: false
  }),
  getters: {},
  actions: {
    async initializeStore() {
        let tokens = JSON.parse(localStorage.getItem("tokens", null));
        if (tokens) {
            await this.initUser(tokens);
        } else {
          this.user = null
          this.tokens = null
        }
        await this.checkUsername("")
        this.isInit = true
        return null
    },
    async checkUsername(username) {
      let response = await apiClient.get(`users_info/check-availability/?username=${username}`)
      return response.data.is_available;
    },
    async initUser(tokens) {
      if (tokens) {
        apiClient.setAuthHeaders(tokens.access);
        this.tokens = tokens;
        localStorage.setItem("tokens", JSON.stringify(tokens))
        await this.loadUser();
      }
    },
    async login(username, password) {
      return apiClient.post("auth/jwt/create/", { username, password })
      .then(response => {
        if (response.status >=200 && response.status < 400) {
           this.initUser(response.data).then(() => router.push("/materials"))
        } else {
          return response.data
        }
      })
      .catch(error => {return Promise.reject(error)})
    },
    async loadUser() {
      return await apiClient.get("auth/users/me/").then(response => this.user = response.data).catch((error)=> {
        console.log("load error: ", error)
      })
    },
    async logout() {
      await apiClient.get("auth/jwt/me/")
      localStorage.removeItem("tokens");
      this.tokens = null;
      this.user = null;      
      // document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    },
    async registration(username, email, password) {
      return apiClient.post("/auth/users/", { username, email, password }, {
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFTOKEN',
    }).then(response => {
        if (response.status >=200 && response.status < 400) {
          this.login(username, password)
        } else {
          return response.data
        }
      })
      .catch(error => {return Promise.reject(error)})
      
    },
    async updateInfo(data) {
        return apiClient.patch(`users_info/users_info/${this.user.user_info.id}/`, data).then(response => 
          {
            if (response.status >=200 && response.status < 400) {
             this.user.user_info = response.data
            } else {
              return response.data
            }
          })
    },
    async updateSettings(data) {
      return apiClient.patch(`users_info/users_settings/${this.user.user_settings.id}/`, data).then(response => 
        {
          if (response.status >=200 && response.status < 400) {
           this.user.user_settings = response.data
          } else {
            return response.data
          }
        })
  },
    async resetPassword(current_password, new_password) {
      return apiClient.post("/auth/users/set_password/", {new_password, current_password})
      .then(() => router.push("/auth"))
    }
  },
});
