import {apiClient} from "../api";

export const defaultStore = function (
  entityUrl,
  state = {},
  getters = {},
  actions = {}
) {
  return Object.assign(
    {},
    {
      state: () => ({
        ...state,
        entities: {},
        entityURL: entityUrl,
        isInit: false,
      }),
      getters: {
        ...getters,
        entitiesList: (state) => {
          return Object.values(state.entities).reverse();
        },
        getById: (state) => (entityId) => {
          return state.entities[entityId]
        },
        getByField: (state) => (field, value, isUniq=true) => {
          let res = Object.values(state.entities).filter(ent => ent[field] == value)
          return isUniq ? (res.length > 0 ? res[0] : null) : res
        }
      },
      actions: {
        ...actions,
        async init() {
          if (this.isInit) return;
          let response = await apiClient.get(this.entityURL);
          if (response.data) {
            response.data.forEach((entity) => {
              this.entities[entity.id] = entity;
            });
            this.isInit = true;
          }
        },
        async retrieveByID(entityId) {
          let response = await apiClient.get(
            this.entityURL + entityId + "/"
          );
          if (response.status >= 200 && response.status < 300) {
            this.entities[entityId] = response.data;
          } else {
            throw new Error(response.error);
          }
          return this.entities[entityId];
        },
        async add(data, config = null) {
          let response = await apiClient.post(
            this.entityURL,
            data,
            config || {}
          );
          if (response.status >= 200 && response.status < 300) {
            this.entities[response.data.id] = response.data;
          } else {
            throw new Error(response.error);
          }
          return this.entities[response.data.id];
        },
        async delete(entityId) {
          let response = await apiClient.delete(
            this.entityURL + entityId + "/"
          );
          if (response.status >= 200 && response.status < 300)
            delete this.entities[entityId];
        },
        async update(entityId, data) {
          let response = await apiClient.patch(
            this.entityURL + entityId + "/",
            data
          );

          if (response.data) {
            this.entities[entityId] = {
              ...this.entities[entityId],
              ...response.data,
            };
          }
        },
      },
    }
  );
};
