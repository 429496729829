<template>
    <q-table dense class="my-sticky-header-table" v-if="rows && rows.length" style="max-height: 300px" flat bordered :hide-bottom="hideBottom" :rows="rows" :columns="headers"
        virtual-scroll :rows-per-page-options="[0]">
        <template v-slot:body="props">
            <q-tr :props="props">
                <q-td v-for="(item, index) in props.row" :key="index">
                    {{ item }}
                </q-td>
            </q-tr>
        </template>
    </q-table>
</template>

<script>
import { ref } from 'vue'
export default {
    props: {
        data: {
            require: false,
        },
        fixedFloat: {
            require: false
        },
        headers: {
            require: false,

        },
        hideBottom: {
            require: false,
            default: false
        }
    },
    setup(props) {
        let mapped = props.data ? props.data.map((row) => row.map(item => {
            if (typeof (item) == 'number' && !!props.fixedFloat) {
                return item.toFixed(props.fixedFloat)
            }
            return item
        })) : []
        return {
            rows: ref(mapped)
        }
    },
    methods: {
    },
    computed: {
    }
}
</script>
<style lang="sass">
.my-sticky-header-table 

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: #efefef

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px

  /* prevent scrolling behind sticky top row on focus */
  tbody
    /* height of all previous header rows */
    scroll-margin-top: 48px
</style>
<style>
.table {
    border: 1px solid #eee;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 20px;
}

.table th {
    font-weight: bold;
    padding: 5px;
    background: #efefef;
    border: 1px solid #dddddd;
}

.table td {
    padding: 5px 10px;
    border: 1px solid #eee;
    text-align: left;
}

.table tbody tr:nth-child(odd) {
    background: #fff;
}

.table tbody tr:nth-child(even) {
    background: #dbdbdb;
}
</style>