<template>
  <q-page class="row justify-center q-pa-md ">
  <div class="col-8">
    <user-billing-card></user-billing-card>
  </div>
</q-page></template>

<script>
import UserBillingCard from '../components/UserBillingCard.vue'

export default {
  components: { UserBillingCard },

}
</script>

<style></style>