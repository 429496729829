<template>
  <q-dialog v-model="dialogPopupStore.show" position="standard">
    <dialog-popup-content-render  v-if="dialogPopupStore.message || dialogPopupStore.data" :message="dialogPopupStore.message" :data="dialogPopupStore.data"></dialog-popup-content-render>
  </q-dialog>
</template>

<script>
import { useDialogPopupStore } from "../store/dialog_popup";
import DialogPopupContentRender from './DialogPopupContentRender.vue';
export default {
  components: { DialogPopupContentRender },
  setup() {
    return {
      dialogPopupStore: useDialogPopupStore(),
    }
  },
}
</script>

<style>
</style>