<template>
    <q-form class="q-gutter-md q-mt-md">
        <q-input dense outlined clearable v-model="userInfo.first_name" label="Name" />
        <q-input dense outlined clearable v-model="userInfo.second_name" label="Surname" />
        <q-input dense outlined clearable v-model="userInfo.organization" label="Patronymic" />
        <q-input dense outlined clearable v-model="userInfo.stuff" label="Stuff" />
        <q-btn unelevated color="primary" @click="saveUserInfo" label="Save" ></q-btn>
    </q-form>
</template>

<script>
import { ref } from 'vue'
import { useUserStore } from "../store/user";

export default {
    setup() {
        return {
            userStore: useUserStore(),
            tab: ref('user_info'),
        }
    },
    data() {
        return {
            userInfo: this.userStore.user ? { ...this.userStore.user.user_info } : {}
        }
    },
    methods: {
        saveUserInfo() {
            this.userStore.updateInfo({
                first_name: this.userInfo.first_name,
                second_name: this.userInfo.second_name,
                organization: this.userInfo.organization,
                stuff: this.userInfo.stuff
            })
        }
    }
}
</script>

<style></style>