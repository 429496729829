<template>
  <q-page class="row col-12 justify-start q-ma-sm">
    <div v-if="!!!chemicalCardsStore.isInit && !chemicalCard" class="q-pa-md flex flex-center">
      <q-circular-progress indeterminate rounded size="50px" color="primary" class="q-ma-md" />
    </div>
    <div v-else class="row col-12">
     <chemical-card-view v-if="!!chemicalCard" :chemicalCard="chemicalCard">
     </chemical-card-view>
    </div>
  </q-page>
</template>

<style></style>

<script>
import useChemicalCardsStore from '../store/chemical_cards'
import { useUserStore } from '../store/user'
import { useTagsStore } from '../store/tags'
import ChemicalCardView from '../components/ChemicalCardView'


import { ref } from 'vue'
export default {
  components: { ChemicalCardView },
  setup() {
    return {
      tab: ref('provided'),
      dialog: ref(false),
      chemicalCard: ref(null),
      chemicalCardsStore: useChemicalCardsStore(),
      tagsStore: useTagsStore(),
      userStore: useUserStore(),
    }
  },
  created() {
    this.tagsStore.init().then(() => this.chemicalCardsStore.init().then(() => {
      this.chemicalCard = this.chemicalCardsStore.getByField("local_user_id", this.$route.params.id)
    }))

  }
}
</script>
<style scoped></style>
