<template>
  <q-page class="row justify-center">
    <q-card v-if="userStore.isInit" flat style="width: 600px;">
      <q-card-section>
        <div class="text-h3 text-center">Welcome!</div>
        <div class="text-grey text-center">by MatSolver</div>
      </q-card-section>
      <div>
        <q-card flat bordered class="q-pa-sm" v-if="!userStore.user">
          <q-tabs v-model="tab" dense class="text-grey" active-color="primary" indicator-color="primary" align="justify"
            narrow-indicator>
            <q-tab class="text-primary" name="loginTab" label="Login" />
            <q-tab class="text-primary" name="registrationTab" label="Registration" />
          </q-tabs>
          <q-separator />

          <q-tab-panels class="q-pa-lg" v-model="tab" animated>
            <q-tab-panel name="loginTab">
              <!-- <div class="text-h6 text-primary">Вход</div> -->
              <q-form class="q-gutter-md q-mt-md" @submit="loginUser">
                <q-input dense outlined clearable v-model="username" label="Username" />
                <q-input dense outlined clearable v-model="password" :type="isPwd ? 'password' : 'text'" label="Password">
                  <template v-slot:append>
                    <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                      @click="isPwd = !isPwd" />
                  </template>
                </q-input>
                <div class="column justify-evenly items-start">

                  <q-btn dense unelevated type="submit" color="primary-accent" size="lg" class="full-width"
                    label="Login" />
                  <q-btn class="q-ma-xs self-end" dense outline unelevated color="secondary" type="a"
                    :to="{ name: 'reset_password_request' }">Reset password</q-btn>
                </div>
              </q-form>
            </q-tab-panel>

            <q-tab-panel name="registrationTab">
              <!-- <div class="text-h5 text-primary">Регистрация</div> -->
              <q-form class="q-gutter-md q-mt-md" @submit="registrationUser" @reset="onReset">
                <q-input :debounce="1000" dense outlined clearable v-model="username" label="Username"
                  :rules="[val => !!val || 'Provide username', isValidUsername]" />
                <q-input dense outlined clearable v-model="email" :rules="[val => !!val || 'Provide email', isValidEmail]"
                  type="email" label="Email" />
                <q-input dense outlined clearable v-model="password" :type="isPwd ? 'password' : 'text'" hint="Password">
                  <template v-slot:append>
                    <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                      @click="isPwd = !isPwd" />
                  </template>
                </q-input>
                <q-input dense outlined clearable v-model="repeatPassword" :type="isRepeatPwd ? 'password' : 'text'"
                  hint="Repeat password">
                  <template v-slot:append>
                    <q-icon :name="isRepeatPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                      @click="isRepeatPwd = !isRepeatPwd" />
                  </template>
                </q-input>
                <div class="column justify-evenly items-start">
                  <q-toggle class="q-my-xs" v-model="accept" label="Accept terms and conditions" />

                  <q-btn :disable="!accept" dense unelevated color="primary" size="lg" class="q-my-xs full-width"
                    type="submit" label="Register" />

                  <q-btn label="Reset" type="reset" dense unelevated outline color="grey-7" size="md"
                    class="q-my-xs full-width" @click="onReset" />
                </div>
              </q-form>
            </q-tab-panel>

          </q-tab-panels>
        </q-card>
      </div>
    </q-card>

  </q-page>
</template>

<script>
import { ref } from 'vue'
import { useUserStore } from "../store/user";
import { useDialogPopupStore } from "../store/dialog_popup";
export default {
  setup() {
    return {
      userStore: useUserStore(),
      dialogPopupStore: useDialogPopupStore(),
      password: ref(''),
      email: ref(''),
      repeatPassword: ref(''),
      isPwd: ref(true),
      username: ref(''),
      isRepeatPwd: ref(true),
      tab: ref('loginTab'),
      accept: ref(false),
      alert: ref(false),
      alertMessage: ref("")
    }
  },
  methods: {
    showAlert(error) {
      this.dialogPopupStore.showAlertFromHTTPError(error)
    },
    isValidEmail(val) {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || 'неверный формат почты';
    },
    async isValidUsername(val) {
      return await this.userStore.checkUsername(val) || 'логин уже занят';
    },
    loginUser() {
      this.userStore.login(this.username, this.password).catch(error => {
        this.dialogPopupStore.showAlertFromHTTPError(error)
      });
    },
    registrationUser() {
      if (this.password == this.repeatPassword) {
        this.userStore.registration(this.username, this.email, this.password, this.repeatPassword).catch((error) => {
          this.dialogPopupStore.showAlertFromHTTPError(error)
        })
      } else {
        this.dialogPopupStore.showAlertData({ password: 'Пароли не совпадают' })
      }
    },
    onReset() {
      this.username = null
      this.password = null
      this.repeatPassword = null
      this.accept = false
    }
  },
  computed: {
  },
  created() {
    this.userStore.initializeStore().then(() => {
      if (this.userStore.user) {
        this.$router.push("/materials")
      }
    })
  }
}
</script>