<template>
    <q-card bordered flat>
        <q-expansion-item v-model="expanded" switch-toggle-side bordered expand-separator toggle-aria-label="Show detail">
            <template v-slot:header>
                <q-item-section avatar>
                    <q-avatar icon="calculate" color="primary" text-color="white" />
                </q-item-section>

                <q-item-section>
                    <div class="text-subtitle1">
                        {{ calculation.calculation_name }}
                    </div>
                    <div class="">
                        Calculation # {{ calculation.local_user_id }}
                    </div>
                </q-item-section>

                <q-item-section side>
                    <div class="q-pa-md">
                        <q-chip :color="colors[calculation.state]" text-color="white" :icon="icons[calculation.state]">
                            {{ calculation.state }} <b v-if="calculation.siman_state">&nbsp;{{ calculation.siman_state
                            }}</b>
                        </q-chip>
                    </div>
                </q-item-section>
            </template>
            <q-card>
                <q-card-section>
                    <compound-view v-if="calculation.end" :files="calculation.calculation_files"
                        :showJmol="namesWithJmol.indexOf(calculation.calculation_type) != -1"
                        :showDownloadButton="namesWithDownloadButton.indexOf(calculation.calculation_type) != -1"
                        :compound="calculation.end" :jmol_file="customJmolFile"></compound-view>
                </q-card-section>
                <q-card-section>
                    <div class="row justify-between text-bold">
                        <div class="col-12">

                        </div>
                        <div class="col-5 row">
                            <div class="col-6">Calculation Type: </div>
                            <div class="col-6">{{ calculation.calculation_type ?
                                calculation.calculation_type.toUpperCase() : "" }}</div>
                            <div class="col-6">Calculation Structure Type:</div>
                            <div class="col-6"> {{ calculation.structure_type ?
                                calculation.structure_type.toUpperCase() : "" }}</div>
                            <div class="col-6">Calculation Precision:</div>
                            <div class="col-6"> {{ calculation.precision ?
                                calculation.precision.toUpperCase() : "" }}</div>
                            <div class="col-6">Extra Options:</div>
                            <div class="col-6">
                                <div class="col-12" v-for="key in Object.keys(calculation.extra_kwargs)" :key="key">
                                    {{ key.toUpperCase() }}: {{ calculation.extra_kwargs[key] }}
                                </div>
                            </div>

                        </div>
                        <div class="col-5 row">
                            <div class="col-6">Created At:</div>
                            <div class="col-6"> {{ getDateView(calculation.created_at) }}</div>
                            <div class="col-6">Duration: </div>
                            <div class="col-6"> {{ durationStr }}</div>
                            <div class="col-6">Core Number: </div>
                            <div class="col-6"> {{ (calculation.calculator_resource_info || {}).corenum || 1 }}</div>
                            <div class="col-6">RAM: </div>
                            <div class="col-6"> {{ (calculation.ram_memory || 0.0).toFixed(2) }} GB</div>
                            <div class="col-6">Total Cost:</div>
                            <div class="col-6"> {{ calculation.total_cost || 1 }} {{ calculation.total_cost_currency }}
                            </div>
                            <div class="col-6">Updated At: </div>
                            <div class="col-6"> {{ getDateView(calculation.updated_at) }}</div>
                        </div>
                        <div class="col-4 row justigy-end">
                        </div>
                    </div>
                </q-card-section>
                <q-card-section>
                </q-card-section>
            </q-card>
        </q-expansion-item>
    </q-card>
</template>

<script>
import CompoundView from './CompoundView.vue'
import { COLOR_NAME_BY_STATE, ICON_NAME_BY_STATE, CALCULATION_STATES, CALCULATION_UNIQ_ID } from '@/shared/calculations'
import { getDateView } from '@/shared/translate'
import { apiClient } from '../api'
import { ref } from 'vue'
export default {
    components: { CompoundView },
    props: {
        calculation: {
            require: true,
            type: Object
        },
    },
    setup() {
        return {
            expanded: ref(true),
            states: CALCULATION_STATES,
            colors: COLOR_NAME_BY_STATE,
            icons: ICON_NAME_BY_STATE,
            namesWithJmol: [CALCULATION_UNIQ_ID.full, CALCULATION_UNIQ_ID.dynamic, CALCULATION_UNIQ_ID.ions],
            namesWithDownloadButton: [CALCULATION_UNIQ_ID.ions, CALCULATION_UNIQ_ID.full],
        }
    },
    computed: {
        customJmolFile() {
            if (this.calculation.calculation_type == CALCULATION_UNIQ_ID.dynamic) {
                return this.calculation.calculation_files["400.OUTCAR"]
            }
            return null
        },
        duration() {
            if (this.calculation.state != CALCULATION_STATES.FAIL && this.calculation.state != CALCULATION_STATES.SUCCESS) {
                const opDiff = new Date() - new Date(this.calculation.created_at)
                return (opDiff / 1000).toFixed(0)
            }
            return this.calculation.duration
        },
        durationStr() {
            let day = 86400;
            let hour = 3600;
            let minute = 60;

            let totalseconds = this.duration;

            let daysout = Math.floor(totalseconds / day);
            let hoursout = Math.floor((totalseconds - daysout * day) / hour);
            let minutesout = Math.floor((totalseconds - daysout * day - hoursout * hour) / minute);
            let secondsout = totalseconds - daysout * day - hoursout * hour - minutesout * minute;
            return `${daysout} days; ${hoursout} hours; ${minutesout} mins; ${secondsout.toFixed()} secs`
        }
    },
    methods: {
        getDateView,
        downloadCalculationFiles() {
            apiClient.post("/calculations/download_file/", { calculation: this.calculation.id })
        }
    }
}
</script>
 

<style></style>