function getDateView(dateStr) {
    let date = new Date(dateStr)
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}
function formulaView(formula) {    
      let result = '';
      let currentNumber = '';
      for (let i = 0; i < formula.length; i++) {
        const char = formula[i];
        if (char >= '0' && char <= '9') {
          currentNumber += char;
        } else {
          if (currentNumber !== '') {
            result += `<sub>${currentNumber.split('').map(digit => digit).join('')}</sub>`;
            currentNumber = '';
          }
          result += char;
        }
      }
    
      if (currentNumber !== '') {
        result += `<sub>${currentNumber.split('').map(digit => digit).join('')}</sub>`;
      }
      return result
}
export {getDateView, formulaView}