<template>
  <router-view />
</template>

<script>
import { useUserStore } from "./store/user";

export default {
  name: 'App',
  setup() {
    return {
      userStore: useUserStore(),
    }
  },
  created() {
    this.userStore.initializeStore()
  }
}
</script>

<style>
</style>