<template>
  <q-page class="row justify-center">
    <div v-if="userStore.user" class="column col-12 col-md-6 q-pt-md">
      <div class="text-h6 q-mb-md">Settings</div>
      <q-card flat>
      <q-tabs
        v-model="tab"
        dense
        
        class="text-primary"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab name="account" label="Account" />
        <q-tab name="user_info" label="User Info" />
        <!-- <q-tab name="billing" label="Billiing" /> -->
        <q-tab name="service_settings" label="Service Settings" />
        <q-tab name="statistics" label="Statistics" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="account">
          <div class="text-h6">Account</div>
          <account-form style="max-width: 500px"></account-form>
        </q-tab-panel>
        <q-tab-panel name="user_info">
          <div class="text-h6">User Info</div>
          <user-info-form style="max-width: 500px"></user-info-form>
        </q-tab-panel>

        <!-- <q-tab-panel name="billing">
          <div class="text-h6">Billiing</div>
          <user-billing-card></user-billing-card>
        </q-tab-panel> -->

        <q-tab-panel name="service_settings">
          <div class="text-h6">Service Settings</div>
          <user-service-settings-form></user-service-settings-form>
        </q-tab-panel>
        <q-tab-panel name="statistics">
          <div class="text-h6">Statistics</div>
          <user-statistics></user-statistics>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    </div>

  </q-page>
</template>

<script>
import {ref} from 'vue'
import UserInfoForm from '../components/UserInfoForm.vue'
import UserStatistics from '../components/UserStatistics.vue'
import UserServiceSettingsForm from '../components/UserServiceSettingsForm.vue'
import { useUserStore } from "../store/user";
import AccountForm from '../components/AccountForm.vue';
// import UserBillingCard from '../components/UserBillingCard.vue';

export default {
  name: "AccountPage",
  components: { UserInfoForm, AccountForm, UserServiceSettingsForm, UserStatistics },
  setup() {
      return {
          userStore: useUserStore(),
          tab: ref('account'),
      }
  }
}
</script>

<style></style>