<template>
  <q-form class="q-gutter-md q-mt-md">
    <q-select outlined options-dense dense v-model="selectedCard"
            :options="cardsToSelect" option-value="id" option-label="text">
            <template v-slot:prepend>
                <q-icon size="20px" dense name="receipt_long" />
            </template>
        </q-select>

    <q-btn @click="onSubmit" style="width: 100%" icon="save">Create from existing card</q-btn>
  </q-form>
</template>

<script>
import { ref } from 'vue'
import useChemicalCardStore from '../store/chemical_cards'
import { useDialogPopupStore } from "../store/dialog_popup";
import { getCalculationFromCard, CALCULATION_UNIQ_ID, CALCULATION_STATES } from "../shared/calculations";
export default {
  setup() {
    let store = useChemicalCardStore();
    store.init()
    return {
      chemicalCardStore: store,
      selectedCard: ref(''),
      cardsToSelect: store.entitiesList
      .map(card => {
        return {
          card: card,
          end: getCalculationFromCard(card, CALCULATION_UNIQ_ID.full, CALCULATION_STATES.SUCCESS)?.end
        }})
      .filter(el => el.end)
      .map(el =>{ return  {id: el.card.id, text: `${el.end.chemical_formula} Card ${el.card.local_user_id}`}}),
      popupStore: useDialogPopupStore(),
    }
  },
  methods: {
    onSubmit() {
      if (!this.selectedCard) return 
      this.loading = true // add loading state to submit button
      this.chemicalCardStore.add({chemical_card_id: this.selectedCard.id})
        .then((entity) => {
          this.loading = false
          this.$router.push({ name: 'chemical_cards_info', params: { id:  entity.local_user_id} })
        })
        .catch((error) => {
          this.loading = false
          this.popupStore.showAlertFromHTTPError(error)
        })
    }
  },
}
</script>

<style></style>