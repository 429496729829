<template>
    <q-card flat bordered class="my-card">
        <q-card-section class="text-white">
            <div class="text-h6">JMOL</div>
            <div class="col-12" style="height: 500px" ref="jmolDiv" id="jmolDiv"></div>
        </q-card-section>

        <q-separator />

        <q-card-actions align="left">
            <!-- <q-input style="width: 100%" dense flat></q-input> -->

        </q-card-actions>
    </q-card>
</template>
<script>

const hashCode = function (s) {
    return s.split("").reduce(function (a, b) {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a;
    }, 0);
}
/* global Jmol */
// eslint-disable-next-line
/* global jmolApplet */
export default {
    props: {
        source_file: {
            require: true
        }
    },
    setup(props) {
        return {
            
            jmolID: 'jmolApplet' + hashCode(JSON.stringify(props.source_file))
        }
    },
    mounted() {
        const host = window.location.protocol + "//" + window.location.hostname
        const info = {
            width: "100%",
            height: "100%",
            debug: false,
            color: 'white',
            zIndexBase: 1,
            isAsync: true,
            async: true,
            addSelectionOptions: false,
            use: 'HTML5',
            serverURL: `${host}/jmol/jsmol/php/jsmol.php`,
            j2sPath: `${host}/jmol/jsmol/j2s`,
            disableInitialConsole: true,
            script: `set platformSpeed 8; LOAD ASYNC ${this.source_file}`,
        }
        let jsmolAppletd = Jmol.getApplet(
            this.jmolID,
            info
        )
        this.$refs.jmolDiv.innerHTML = jsmolAppletd._code
        jsmolAppletd._cover(false)
        Jmol.script(jsmolAppletd, "set pickCallback 'handleAtomSelect'")
    },
}
</script>

<style></style>