<template>
  <div class="q-my-auto row justify-between">
    <div class="col-4 align-center">
        <q-badge outline :color="colors[calculation.state]" :label="calculation.state" />
    </div>
    
    <div class="col-7">Calculation # {{ calculation.local_user_id }}</div>
    <q-icon class="col-1" :name="icons[calculation.state]"></q-icon>
    </div>
</template>

<script>
import { COLOR_NAME_BY_STATE, ICON_NAME_BY_STATE } from '@/shared/calculations'
export default {
    props: {
        calculation: {
            type: Object,
            require: true
        }
    },
    setup() {
        return {
            colors: COLOR_NAME_BY_STATE,
            icons: ICON_NAME_BY_STATE
        };
    }
}
</script>

<style>

</style>