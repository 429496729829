<template>
    <q-form class="q-gutter-md q-mt-md">
        <div class="row">
            <div class="col-6">Username: {{ userStore.user.username }}</div>
            <div class="col-6">Mode: {{ userInfo.mode }}</div>
        </div>
        <q-input dense outlined clearable v-model="userStore.user.email" label="Email" />
        <q-input dense outlined clearable v-model="current_password" :type="isPwd ? 'password' : 'text'"
            label="Current password">
            <template v-if="current_password.length" v-slot:append>
                <q-icon :name="isCurrentPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
            </template>
        </q-input>
        <q-input dense outlined clearable v-model="new_password" :type="isPwd ? 'password' : 'text'" label="New password">
            <template v-if="new_password.length" v-slot:append>
                <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
            </template>
        </q-input>
        <q-input dense outlined clearable v-model="repeat_password" :type="isRepeatPwd ? 'password' : 'text'"
            label="Repeat password">
            <template v-if="repeat_password.length" v-slot:append>
                <q-icon :name="isRepeatPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                    @click="isRepeatPwd = !isRepeatPwd" />
            </template>
        </q-input>
        <q-btn unelevated color="primary" label="Save" @click="resetPassword"/>
    </q-form>
</template>

<script>
import { ref } from 'vue'
import { useUserStore } from "../store/user";

export default {
    setup() {
        return {
            userStore: useUserStore(),
            tab: ref('user_info'),
            current_password: ref(""),
            new_password: ref(""),
            repeat_password: ref(""),
            isCurrentPwd: ref(true),
            isRepeatPwd: ref(true),
            isPwd: ref(true),
        }
    },
    data() {
        return {
            userInfo: this.userStore.user ? { ...this.userStore.user.user_info } : {}
        }
    },
    methods: {
        resetPassword() {
            if (this.new_password == this.repeat_password) {
                this.userStore.resetPassword({current_password:this.current_password, new_password: this.new_password})
            } else {
                alert("новые пароли не совпадают")
            }
        }
    }
}
</script>

<style></style>