<template>
    <q-card>
        <q-card-section>
            <div class="text-h6">Promo Code</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
            You can request a promo code by writing a request to matsolver.ai@ya.ru Please specify a reason why do you need
            Matsolver.
        </q-card-section>
        <q-card-section>
            <q-card flat bordered class="q-pa-sm">
                <div class="text-h6 text-primary">Promo Code</div>
                <q-form class="q-gutter-md q-mt-md" @submit="activatePromoCode()">
                    <q-input dense outlined clearable v-model="promocode" label="enter promo code" />
                    <div class="column justify-evenly items-start">
                        <q-btn dense unelevated type="submit" color="primary-accent" size="lg" class="full-width"
                            label="Activate" />
                    </div>
                </q-form>
            </q-card>
        </q-card-section>
        <q-card-actions align="right">
            <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
    </q-card>
</template>

<script>
import { useDialogPopupStore } from "../store/dialog_popup";
import { apiClient } from '@/api';
import { ref } from 'vue'
export default {
    setup() {
        return {
            dialogPopupStore: useDialogPopupStore(),
            promocode: ref(''),
            accept: ref(false),
            alert: ref(false),
            alertMessage: ref(""),

        }
    },
    methods: {
        showAlert(error) {
            this.dialogPopupStore.showAlertFromHTTPError(error)
        },
        activatePromoCode() {
            if (this.promocode) {
                apiClient.post('/billing/activate_promo_code/', {
                    code: this.promocode
                }).then(() => {
                    this.$router.go({ name: 'user_billing' })
                }).catch((error) => {
                    this.dialogPopupStore.showAlertFromHTTPError(error)
                })
            }
        },
    }
}
</script>

<style></style>