<template>
    <div class="row item-start">
        <div v-if="!chemicalsCardsStore.isInit" class="q-pa-md justify-start">
            <q-circular-progress indeterminate rounded size="50px" color="primary" class="q-ma-md" />
        </div>
        <q-table v-if="chemicalsCardsStore.isInit" flat bordered title="My materials" :rows="getFiltered" :columns="columns"
            :visible-columns="visibleColumns" :rows-per-page-options="[10, 15, 20, 25, 50, 0]" row-key="name">
            <template v-slot:top="props">
                <div class="col-2 q-table__title">My materials</div>
                <q-space />
                <q-select v-model="visibleColumns" multiple outlined dense options-dense display-value="Select columns"
                    emit-value map-options :options="columns" option-value="name" options-cover style="min-width: 150px" />


                <q-btn flat dense :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
                    @click="props.toggleFullscreen" class="q-ml-md" />
            </template>
            <template v-slot:top-right>
                <q-btn @click="clearFilter()" flat label="Clear Filter" icon="clear" />
            </template>
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props">
                        {{ col.label }} <sup v-if="col.name == 'volume'">3</sup>
                    </q-th>
                </q-tr>
            </template>
            <template v-slot:body="props">
                <q-tr style="cursor: pointer;" :props="props" @click="onRowClick(props.row)">
                    <q-td key="chemical_card_id" :props="props">
                        {{ props.row.local_user_id }}
                    </q-td>
                    <q-td key="chemical_formula" :props="props" v-html="formulaView(props.row.chemical_formula)">
                    </q-td>
                    <q-td key="symetry_group_name" :props="props"
                        v-html="SYMMETRY_GROUPS_SHORT_NAMES[props.row.symetry_group_number]">
                    </q-td>
                    <q-td key="symetry_group_number" :props="props">
                        {{ props.row.symetry_group_number }}
                    </q-td>
                    <q-td key="number_of_atoms" :props="props">
                        {{ props.row.number_of_atoms }}
                    </q-td>
                    <q-td key="volume" :props="props">
                        {{ props.row.volume ? props.row.volume.toFixed(1) : "-" }}
                    </q-td>
                    <q-td key="total_energy_per_atom" :props="props">
                        {{ props.row.total_energy_per_atom ? props.row.total_energy_per_atom.toFixed(5) : "-" }}
                    </q-td>
                    <q-td key="bulk_modulus" :props="props">
                        {{ props.row.bulk_modulus ? props.row.bulk_modulus.toFixed(0) : "-" }}
                    </q-td>
                    <q-td key="is_mechanically_stable" :props="props">
                        {{ props.row.is_mechanically_stable != null ? (props.row.is_mechanically_stable ? 'yes' : 'no') :
                            "-" }}
                    </q-td>
                    <q-td key="is_dynamically_stable" :props="props">
                        {{ props.row.is_dynamically_stable != null ? (props.row.is_dynamically_stable ? 'yes' : 'no') : "-"
                        }}
                    </q-td>
                    <q-td key="tags" style="max-width: 100px;" :props="props">
                        <div class="row">
                            <i v-for="tag in props.row.tags" :key="tag">{{ tag }},</i>
                        </div>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:bottom-row>
                <q-tr no-hover>
                    <q-th v-for="col in columns.filter(col => col && visibleColumns.indexOf(col.name) != -1)"
                        :key="col.name" class="text-italic text-purple">
                        <q-input v-if="!col.custom_input" :debounce="1000" v-model="sortInputes[col.field]" dense
                            :label="col.label"></q-input>
                        <tags-filter @updateValue="sortInputes[col.field] = $event || []"
                            v-if="col.custom_input == 'tags-filter'"></tags-filter>
                    </q-th>

                </q-tr>
            </template>
        </q-table>
    </div>
</template>

<script>
// import MaterialCard from './MaterialCard.vue'
import useChemicalCardsStore from '@/store/chemical_cards'
import { useTagsStore } from '@/store/tags'
import { ref } from 'vue'
import { SYMMETRY_GROUPS_SHORT_NAMES } from '@/shared/consts'
import { CALCULATION_UNIQ_ID, CALCULATION_STATES, getCalculationFromCard } from '@/shared/calculations'
import { formulaView } from '@/shared/translate'
import TagsFilter from './TagsFilter.vue'
export default {
    components: { TagsFilter },

    setup() {
        const columns = [
            { name: 'chemical_card_id', align: 'left', label: 'ID', field: 'chemical_card_id', sortable: true },
            { name: 'chemical_formula', align: 'left', label: 'Formula', field: 'chemical_formula', sortable: true },
            { name: 'symetry_group_name', align: 'left', label: 'SG name', field: 'symetry_group_name', sortable: true },
            { name: 'symetry_group_number', align: 'left', label: 'SG number', field: 'symetry_group_number', sortable: true, unshow: true },
            { name: 'number_of_atoms', align: 'left', label: 'N atoms', field: 'number_of_atoms', sortable: true },
            { name: 'volume', align: 'left', label: 'Volume, Å', field: 'volume', sortable: true },
            { name: 'total_energy_per_atom', align: 'left', label: 'Total E, eV/at', field: 'total_energy_per_atom', sortable: true },
            { name: 'bulk_modulus', align: 'left', label: 'Bulk Modulus, GPa', field: 'bulk_modulus', sortable: true },
            { name: 'is_mechanically_stable', align: 'left', label: 'Mech. stable?', field: 'is_mechanically_stable', sortable: true },
            { name: 'is_dynamically_stable', align: 'left', label: 'Dyn. stable?', field: 'is_dynamically_stable', sortable: true },
            { name: 'tags', align: 'left', label: 'Tags', field: 'tags', sortable: false, custom_input: "tags-filter" },

        ]
        let defaultVisible = ref(columns.filter(el => !el.unshow).map(col => col.name))
        let visibleColumns = JSON.parse(localStorage.getItem("chemical_cards_visible_columns")) || defaultVisible
        return {
            formulaView: formulaView,
            SYMMETRY_GROUPS_SHORT_NAMES: SYMMETRY_GROUPS_SHORT_NAMES,
            sortInputes: ref({
                chemical_formula: null,
                symetry_group_name: null,
                symetry_group_number: null,
                number_of_atoms: null,
                volume: null,
                tags: [],

            }),
            columns: ref(columns),
            visibleColumns: visibleColumns,
            chemicalsCardsStore: useChemicalCardsStore(),
            tagsStore: useTagsStore()
        }
    },
    watch: {
        visibleColumns: {
            handler() {
                this.saveSettings()
            }
        }
    },
    methods: {
        saveSettings() {
            localStorage.setItem("chemical_cards_visible_columns", JSON.stringify(this.visibleColumns))
        },
        onRowClick(entity) {
            this.$router.push({ name: 'chemical_cards_info', params: { id: entity.local_user_id } })
        },
        clearFilter() {
            Object.keys(this.sortInputes).forEach(key => this.sortInputes[key] = null)
        },
    },
    computed: {
        getFiltered() {
            return this.getRows.filter((entity) => {
                let isTarget = Object.keys(this.sortInputes).map(field => {
                    let sortyInputsValue = this.sortInputes[field] || ""
                    if (sortyInputsValue) {
                        let entityValue = entity[field]
                        if (Array.isArray(sortyInputsValue)) {
                            sortyInputsValue = sortyInputsValue.sort()
                            sortyInputsValue = sortyInputsValue.length > 0 ? sortyInputsValue : ""
                        }
                        if (Array.isArray(entityValue)) {
                            entityValue = entityValue.sort()
                            entityValue = entityValue.length > 0 ? entityValue : ""
                        }

                        if (typeof (entityValue) == 'boolean') {
                            entityValue = entityValue ? 'yes' : 'no'
                        } else if (!(typeof (entityValue) == 'string')) {
                            entityValue = JSON.stringify(entityValue)
                        }

                        if (!(typeof (sortyInputsValue) == 'string')) sortyInputsValue = JSON.stringify(sortyInputsValue)
                        return !((entityValue != null ? entityValue : "").toLowerCase().indexOf(sortyInputsValue.toLowerCase()) == -1)
                    }
                    return true
                }).reduce((isTarget, current) => isTarget && current, true)
                return isTarget
            })
        },
        getRows() {
            return this.chemicalsCardsStore.entitiesList.filter(card => !!card.provided).map(card => {
                return {
                    ...card,
                    ...card.provided,
                    tags: card.tags.map((tagID) => this.tagsStore.getById(tagID).text),
                    total_energy_per_atom: getCalculationFromCard(card, CALCULATION_UNIQ_ID.energy, CALCULATION_STATES.SUCCESS)?.end?.total_energy_per_atom,
                    volume: getCalculationFromCard(card, CALCULATION_UNIQ_ID.full, CALCULATION_STATES.SUCCESS)?.end?.volume,
                    bulk_modulus: getCalculationFromCard(card, CALCULATION_UNIQ_ID.full, CALCULATION_STATES.SUCCESS)?.end?.bulk_modulus,
                    is_mechanically_stable: getCalculationFromCard(card, CALCULATION_UNIQ_ID.mechanic, CALCULATION_STATES.SUCCESS)?.end?.is_mechanically_stable,
                    is_dynamically_stable: getCalculationFromCard(card, CALCULATION_UNIQ_ID.dynamic, CALCULATION_STATES.SUCCESS)?.end?.is_dynamically_stable
                }
            }) || []
        }
    },
    created() {
        this.tagsStore.init().then(() => this.chemicalsCardsStore.init())
    }
}
</script>

<style></style>