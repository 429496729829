<template>
  <q-card flat>
      <q-tabs v-model="tab" dense class="bg-grey-2 text-grey-7" align="justify">
        <q-tab name="file" label="Structure File" />
        <q-tab name="materials_project" label="Materials Project" />
        <q-tab name="copy_local" label="Copy from existing" />

      </q-tabs>

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="file">
          <div class="text-h6">Use cif or POSCAR format</div>
          <add-chemical-card-file-form></add-chemical-card-file-form>
        </q-tab-panel>
        <q-tab-panel name="materials_project">
          <div class="text-h6">Materials Project ID</div>
          <add-chemical-card-matproject-form></add-chemical-card-matproject-form>
        </q-tab-panel>
        <q-tab-panel name="copy_local">
          <div class="text-h6">Local database</div>
          <add-chemical-card-matsolver-card></add-chemical-card-matsolver-card>
        </q-tab-panel>
      </q-tab-panels>

    </q-card>
</template>

<script>
import AddChemicalCardFileForm from '../components/AddChemicalCardFileForm.vue'
import AddChemicalCardMatprojectForm from '../components/AddChemicalCardMatprojectForm.vue'
import { ref } from 'vue'
import AddChemicalCardMatsolverCard from './AddChemicalCardMatsolverCard.vue'
export default {
    components: {AddChemicalCardFileForm, AddChemicalCardMatprojectForm, AddChemicalCardMatsolverCard},
    setup() {
    return {
      tab: ref('file')
    }
  }
}
</script>

<style>

</style>